import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  TextField,
} from '@mui/material';
import { StyledFormInputLabel, StyledFormTextfield } from '../../../../styles';
import DeliveryAtAddress from './DeliveryAtAddress';
import DeliveryAtStore from './DeliveryAtStore';
import { DELIVERY_METHODS } from '../../../../variables';

const DetailsForm = ({
  shipping,
  onShippingChange,
  address,
  onStoreChange,
  store,
  onObservationsChange,
  paymentTime,
  onAddressChange,
  role,
  clientAddresses,
}) => {
  const isDeliveryAtAddress = shipping === DELIVERY_METHODS.ADDRESS;
  const isDeliveryAtStore = shipping === DELIVERY_METHODS.STORE;

  return (
    <Box
      sx={{
        width: { xs: '100%', sm: '80%', md: 'auto' },
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        variant='h6'
        sx={{
          fontWeight: 'bold',
          marginBottom: 1,
        }}
      >
        Pagamento
      </Typography>

      <StyledFormTextfield
        label='Método De Pagamento'
        defaultValue='Transferência Bancária'
        InputProps={{
          disabled: true,
        }}
      />

      <StyledFormTextfield
        label='Prazo de Pagamento'
        defaultValue={`${paymentTime} dias`}
        InputProps={{
          disabled: true,
        }}
      />

      <FormControl>
        <TextField
          id='outlined-multiline-static'
          label='Observações'
          multiline
          rows={3}
          defaultValue=''
          onChange={onObservationsChange}
          style={{ marginBottom: 20 }}
        />

        <Box sx={{ marginTop: 2, marginBottom: 2 }}>
          <Typography
            variant='h6'
            sx={{
              fontWeight: 'bold',
            }}
          >
            Envio
          </Typography>
          <Typography
            variant='small'
            sx={{
              marginTop: 1,
            }}
          >
            Poderá escolher apenas um método de envio.
          </Typography>
        </Box>

        <RadioGroup
          value={shipping}
          onChange={onShippingChange}
          name='shipping-options'
        >
          {DELIVERY_METHODS.ADDRESS && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginY: 0.5,
              }}
            >
              <FormControlLabel
                value={DELIVERY_METHODS.ADDRESS}
                control={<Radio />}
                title='Entrega em morada'
                label={
                  <StyledFormInputLabel>Entrega em morada</StyledFormInputLabel>
                }
              />
              {isDeliveryAtAddress && (
                <DeliveryAtAddress
                  address={address}
                  onAddressChange={onAddressChange}
                  role={role}
                  clientAddresses={clientAddresses}
                />
              )}
            </Box>
          )}

          {DELIVERY_METHODS.STORE && (
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                marginY: 0.5,
              }}
            >
              <FormControlLabel
                value={DELIVERY_METHODS.STORE}
                control={<Radio />}
                title='Levantamento em armazém'
                label={
                  <StyledFormInputLabel>
                    Levantamento em armazém
                  </StyledFormInputLabel>
                }
              />
              {isDeliveryAtStore && (
                <DeliveryAtStore
                  store={store}
                  onStoreChange={onStoreChange}
                />
              )}
            </Box>
          )}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default DetailsForm;
