import { Box, Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getProduct } from '../api/products';
// import { addProductToUserHistory } from '../api/user';
import Loading from '../components/Loading';
import ProductDetails from '../components/ProductPage/ProductDetails/ProductDetails';
import ProductImages from '../components/ProductPage/ProductImages';
import ProductRelated from '../components/ProductPage/ProductRelated';
import ProductTabs from '../components/ProductPage/ProductTabs/ProductTabs';
import { MainContainer } from '../styles';
import { MEDIUM_DEVICES_BREAKPOINT } from '../variables';

const ProductPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { productSlug } = useParams();
  const SMALL_DEVICES = useMediaQuery(
    `(max-width:${MEDIUM_DEVICES_BREAKPOINT})`
  );
  const userRole = useSelector((state) => state.user.role);
  const customerId = useSelector(
    (state) => state.user.selectedClient.id || state.user.id
  );
  const [cookies] = useCookies(['userToken']);
  const showPriceToUser = !!cookies.userToken;
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getProduct(productSlug)
      .then((newProduct) => {
        setProduct(newProduct);

        if (userRole === 'customer') {
          // addProductToUserHistory(newProduct.reference, customerId);
        }

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });

    return () => {
      setProduct(null);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, productSlug]);

  if (isLoading) {
    return <Loading />;
  }

  if (!product) {
    return null;
  }

  const images = product?.images;
  const { related, params, datasheet, video } = product;

  const availableParams = params.filter(
    (param) => param.value && param.value !== 'undefined'
  );

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          <Grid xs={12}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: SMALL_DEVICES && 'column',
              }}
              gap={{ xs: 4, md: 8 }}
            >
              <Box
                sx={{
                  maxWidth: '100%',
                  margin: { xs: '0 auto', md: '0' },
                }}
              >
                <ProductImages images={images} />
              </Box>

              <ProductDetails
                product={product}
                showPriceToUser={showPriceToUser}
                customerId={customerId}
              />
            </Box>

            <ProductTabs
              video={video}
              params={availableParams}
              datasheet={datasheet}
              datasheetSMALL_DEVICES={SMALL_DEVICES}
            />

            {related && <ProductRelated related={related} />}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductPage;
