import { Box, Container, Divider, Typography } from '@mui/material';
import React, { useState } from 'react';
// import { useParams } from 'react-router-dom';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getBrands } from '../api';
import { getProducts } from '../api/products';
import Loading from '../components/Loading';
import { imageOnError, slugify } from '../helpers';
import { MainContainer } from '../styles';
import ProductsCarousel from '../components/Carousel/ProductsCarousel';

const BrandPage = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [brand, setBrand] = useState(null);
  const [brandProducts, setBrandProducts] = useState(null);
  const { brandSlug } = useParams();
  const navigate = useNavigate();

  const limitResults = 20;

  useEffect(() => {
    setIsLoading(true);

    getBrands()
      .then(({ brands }) => {
        const filteredBrand = brands.find(({ name }) => slugify(name) === brandSlug);
        setBrand(filteredBrand);

        if (!filteredBrand) return;

        const productFilters = { brands: [filteredBrand.name], limit: limitResults };

        return getProducts({
          productFilters: productFilters,
        })
          .then(({ displayedProducts }) => {
            setBrandProducts(displayedProducts);
            setIsLoading(false);
          })
          .catch(({ response: { status } }) => {
            if (status === 401) navigate('/login');
          });
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [brandSlug, navigate]);

  if (isLoading) {
    return <Loading />;
  }

  if (!brand) return null;

  const { name, description, image } = brand;

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          <Grid
            container
            marginTop={{ md: 4 }}
            xs={12}
          >
            <Grid
              xs={12}
              md={description ? 4 : 12}
              sx={{ textAlign: description ? 'left' : 'center' }}
            >
              {image ? (
                <Box
                  component='img'
                  src={image}
                  onError={imageOnError}
                  sx={{ maxWidth: { xs: '175px', md: '225px' } }}
                  alt={name}
                />
              ) : (
                <Typography variant='h1'>{name}</Typography>
              )}
            </Grid>
            {description && (
              <Grid
                xs={12}
                md={7}
                mdOffset={1}
                marginTop={{ xs: 3, md: 0 }}
              >
                <Typography dangerouslySetInnerHTML={{ __html: description }} />
              </Grid>
            )}
          </Grid>
          {brandProducts && (
            <>
              <Divider sx={{ width: '100%', marginY: { xs: '32px', md: '48px' } }} />
              <Grid xs={12}>
                <Typography
                  variant='h2'
                  sx={{
                    fontSize: '1.75em',
                  }}
                  marginBottom={2}
                >
                  Produtos da marca
                </Typography>
              </Grid>
              <Grid xs={12}>{brandProducts && <ProductsCarousel products={brandProducts} />}</Grid>
            </>
          )}
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default BrandPage;
