import { Button, Divider, Menu, MenuItem } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import { PAGES_SLUGS } from '../../../variables';

const NavbarMenuItem = styled(MenuItem)(({ theme }) => ({
  textTransform: 'none',
  padding: `${theme.spacing(1)} ${theme.spacing(2.5)} `,
}));

const UserMenu = ({
  isMenuOpen,
  setIsMenuOpen,
  userRef,
  accountLinks,
  handleLogout,
}) => {
  //  filter links of 'userMenu'
  const userMenu = accountLinks.filter((item) => item.userMenu);

  return (
    <Menu
      anchorEl={userRef.current}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      keepMounted
      open={isMenuOpen}
      onClose={() => setIsMenuOpen(false)}
      sx={{
        '.MuiMenu-list': {
          paddingTop: 0,
          paddingBottom: 0,
        },
      }}
    >
      {userMenu.map(({ title, url }) => {
        return (
          <li key={`userMenu-${url}`}>
            <>
              <NavbarMenuItem
                component={Link}
                to={`${PAGES_SLUGS.account}/${url}`}
                title={title}
                onClick={() => setIsMenuOpen(false)}
                sx={{
                  fontSize: '0.85rem',
                  fontWeight: 500,
                }}
              >
                {title}
              </NavbarMenuItem>
              <Divider
                sx={{ marginTop: '0!important', marginBottom: '0!important' }}
              />
            </>
          </li>
        );
      })}
      <NavbarMenuItem
        component={Button}
        title='Terminar sessão'
        onClick={handleLogout}
        sx={{
          fontSize: '0.85rem',
          fontWeight: 500,
        }}
      >
        Terminar sessão
      </NavbarMenuItem>
    </Menu>
  );
};

export default UserMenu;
