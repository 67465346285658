import defaultImage from "../assets/default_image.png";

/*
|--------------------------------
| method to add a default image when has an importing error
|--------------------------------
*/
export const imageOnError = (image) => {
  image.onerror = null; // prevents looping
  image.target.src = defaultImage;
};