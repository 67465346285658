import { useEffect, useState } from 'react';
import { Box, Typography, Container } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { getLatestProducts } from '../api/products';
import { MainContainer } from '../styles';
import ProductCard from '../components/ProductCard';
import Loading from '../components/Loading';
import { useNavigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';

function Campaigns() {
  const [isLoading, setIsLoading] = useState(false);
  const [noProductsFound, setNoProductsFound] = useState(false);
  const [products, setProducts] = useState([]);
  const [cookies] = useCookies(['userToken']);
  const showPriceToUser = !!cookies.userToken;

  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);

    getLatestProducts()
      .then((data) => {
        setProducts(data.slice(0, 10));

        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      })
      .then(() => {
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const newNoProductsFound = products.length === 0 && !isLoading;

    setNoProductsFound(newNoProductsFound);
  }, [isLoading, products]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <MainContainer>
      <Container>
        <Box>
          <Typography
            variant='h1'
            sx={{
              textAlign: 'center',
              marginBottom: 3,
            }}
          >
            Novidades
          </Typography>

          <Grid
            container
            spacing={2}
          >
            {!noProductsFound ? (
              products.map((product) => (
                <Grid
                  key={`campaign-product-${product._id}`}
                  xs={6}
                  sm={4}
                  md={3}
                  lg={2.4}
                >
                  <ProductCard
                    product={product}
                    showPrice={showPriceToUser}
                  />
                </Grid>
              ))
            ) : (
              <Grid>
                <Typography variant='h6'>De momento, não existem novidades.</Typography>
              </Grid>
            )}
          </Grid>
        </Box>
      </Container>
    </MainContainer>
  );
}

export default Campaigns;
