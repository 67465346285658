import { Box } from '@mui/material';
import React from 'react';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import './index.css';
import { CUSTOM_THEME_COLORS } from '../../variables';

const ThumbnailsCarousel = ({ images, shownImage, setShownImage }) => {
  const breakpoints = {
    0: {
      slidesPerView: 3,
    },
    400: {
      slidesPerView: 4,
    },
  };
  return (
    <Box sx={{ width: '100%' }}>
      <Swiper
        spaceBetween={8}
        navigation={images.length > 4 ? true : false}
        modules={[Navigation]}
        breakpoints={breakpoints}
        className='thumbnails-carousel'
      >
        {images.map((productImage, index) => (
          <SwiperSlide key={`thumbnail-image-${index}`}>
            <Box
              component='img'
              src={productImage}
              onClick={() => setShownImage(index)}
              hidden
              onLoad={(e) => {
                e.target.hidden = false;
              }}
              onError={(e) => {
                e.target.remove();
              }}
              className={index === shownImage && 'productThumbnailActive'}
              sx={{
                display: 'flex',
                maxWidth: '100%',
                aspectRatio: '1',
                objectFit: 'contain',
                padding: '5px',
                border: `2px solid ${index === shownImage ? CUSTOM_THEME_COLORS.primary : 'transparent'}`,
                boxSizing: 'border-box',
                '&:hover': {
                  border: `2px solid ${CUSTOM_THEME_COLORS.primary}`,
                  cursor: 'pointer',
                },
              }}
              alt={`Imagem thumbnail ${index}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default ThumbnailsCarousel;
