import { Box, MenuItem, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { MEDIUM_DEVICES_BREAKPOINT } from '../../../../variables';

const PresentationalSubcategoriesMenuItem = ({ setIsCategoriesOpen, subcategory, setIsSubcategoriesOpen }) => {
  const { productTypes, title } = subcategory;

  const SMALL_DEVICES = useMediaQuery(`(max-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  function onProductTypesSelection() {
    setIsSubcategoriesOpen(false);
    setIsCategoriesOpen(false);
  }

  return (
    <Box
      sx={{
        display: !SMALL_DEVICES && 'inline-block',
        verticalAlign: 'top',
      }}
    >
      <MenuItem
        sx={{
          pointerEvents: 'none',
        }}
      >
        <Typography
          variant='small'
          sx={{ fontWeight: 700 }}
        >
          {title}
        </Typography>
      </MenuItem>

      {productTypes.map(({ title, url }, index) => (
        <MenuItem
          key={`${title}-${index}`}
          component={Link}
          to={url}
          onClick={onProductTypesSelection}
          style={{ paddingTop: 2, paddingBottom: 2 }}
        >
          <Typography variant='small'>{title}</Typography>
        </MenuItem>
      ))}
    </Box>
  );
};

export default PresentationalSubcategoriesMenuItem;
