import { Box, Container, Grid, Typography, useMediaQuery } from '@mui/material';
import React from 'react';
import { COMPANY_NAME, CUSTOM_THEME_COLORS, MEDIUM_DEVICES_BREAKPOINT } from '../../variables';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import CustomerSupport from './CustomerSupport';
import FooterMobileAccordion from './FooterMobileAccordion';
import SocialMedia from './SocialMedia';

const Footer = () => {
  const LAPTOP_DEVICES = useMediaQuery(`(min-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  return (
    <Box>
      <Box
        sx={{
          marginTop: { xs: 4, md: 5 },
          paddingY: 5,
          backgroundColor: CUSTOM_THEME_COLORS.lightGray,
        }}
      >
        <Container maxWidth='lg'>
          <Grid
            container
            spacing={{ xs: 0, md: 5 }}
          >
            <SocialMedia />

            {!LAPTOP_DEVICES ? (
              <FooterMobileAccordion />
            ) : (
              <>
                <AboutUs />

                <CustomerSupport />

                <ContactUs />
              </>
            )}
          </Grid>
        </Container>
      </Box>

      <Typography
        align='center'
        sx={{ paddingY: 2 }}
      >
        {COMPANY_NAME} © 2023&nbsp;.&nbsp;
        <Typography
          component='a'
          href='https://www.bdcadigital.com/plataforma-de-ecommerce-b2b'
          title='Powered by BDCA Digital'
          variant='small'
          fontWeight={700}
          target='_blank'
          sx={{ color: 'black', textDecoration: 'none', transition: 'opacity 200ms ease', '&:hover': { opacity: '.75' } }}
        >
          B2B Platform by BDCA Digital
        </Typography>
      </Typography>
    </Box>
  );
};

export default Footer;
