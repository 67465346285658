import { Box, Container, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { MainContainer } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';

const InstitucionalPage = ({ props }) => {
  const [pageContent, setPageContent] = useState(props);
  const { title, description, image } = pageContent;

  useEffect(() => {
    setPageContent(props);
    return () => {
      setPageContent();
    };
  }, [props]);

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          {title && (
            <Grid xs={12}>
              <Typography
                variant='h1'
                sx={{ textAlign: 'center', marginBottom: 3 }}
              >
                {title}
              </Typography>
            </Grid>
          )}
          <Grid container>
            <Grid
              xs={12}
              md={6}
            >
              {image && (
                <Box
                  component='img'
                  src={image}
                  alt={title}
                  sx={{ maxWidth: '100%' }}
                />
              )}
            </Grid>
            <Grid
              xs={12}
              md={5.5}
              mdOffset={0.5}
              sx={{
                marginTop: { xs: 2, md: 0 },
              }}
            >
              {description && <Typography dangerouslySetInnerHTML={{ __html: description }} />}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default InstitucionalPage;
