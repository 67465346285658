import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getPage } from '../api';
import Brands from '../components/HomePage/Brands';
import Highlights from '../components/HomePage/Highlights';
import WelcomeImages from '../components/HomePage/WelcomeImages';
import { MainContainer } from '../styles';
import SecondaryBanner from '../components/HomePage/SecondaryBanner';

const HomePage = () => {
  const cart = useSelector((state) => state.cart.cart);
  const [mainBanner, setMainBanner] = useState(null);
  const [campaigns, setCampaigns] = useState(null);
  const [highlights, setHighlights] = useState(null);
  const [brands, setBrands] = useState(null);
  const navigate = useNavigate();
  const pageName = 'homepage';

  useEffect(() => {
    getPage(pageName)
      .then((page) => {
        const { mainBanner, campaigns, products, brands } = page;

        setMainBanner(mainBanner);
        setCampaigns(campaigns);
        setHighlights(products);
        setBrands(brands);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [navigate, cart]);

  if (!mainBanner && !campaigns && !highlights && !brands) return null;

  return (
    <MainContainer>
      {mainBanner && <WelcomeImages mainBanner={mainBanner} />}

      {brands && <Brands brands={brands} />}

      {highlights && <Highlights highlights={highlights} />}

      {campaigns && <SecondaryBanner data={campaigns} />}
    </MainContainer>
  );
};

export default HomePage;
