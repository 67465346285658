import {
  Box,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { styled } from '@mui/material';
import QuantitySelector from './QuantitySelector';
import { CUSTOM_THEME_COLORS } from '../../../variables';

const TableCellStyled = styled(TableCell)(({ theme }) => ({
  [`&.MuiTableCell-head, &.MuiTableCell-body`]: {
    fontSize: '.75rem',
    paddingTop: theme.spacing(0.5),
    paddingBottom: theme.spacing(0.5),
  },
}));

const BadgeDiscount = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  backgroundColor: CUSTOM_THEME_COLORS.primary,
  borderRadius: '10px',
  padding: `${theme.spacing(0.2)} ${theme.spacing(1.25)}`,
  fontWeight: 300,
  color: 'white',
}));

const ProductVariants = ({
  variants,
  showPriceToUser,
  setUpdatedProductQuantity,
}) => {
  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginTop: 4 }}
      >
        <Table
          size='small'
          id='product-variants'
        >
          <TableHead>
            <TableRow>
              <TableCellStyled>Referência</TableCellStyled>
              <TableCellStyled>Nome</TableCellStyled>
              {showPriceToUser && <TableCellStyled>Quantidade</TableCellStyled>}
              <TableCellStyled>Preço</TableCellStyled>
              {showPriceToUser && (
                <>
                  <TableCellStyled>Desconto</TableCellStyled>
                  <TableCellStyled>Preço Líquido</TableCellStyled>
                </>
              )}
            </TableRow>
          </TableHead>

          <TableBody>
            {variants.map((product) => {
              const { reference, title, price, discount, finalPrice } = product;
              return (
                <TableRow
                  key={reference}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCellStyled>{reference}</TableCellStyled>
                  <TableCellStyled>{title}</TableCellStyled>
                  {showPriceToUser && (
                    <TableCellStyled>
                      <QuantitySelector
                        product={product}
                        setUpdatedProductQuantity={setUpdatedProductQuantity}
                        variant
                      />
                    </TableCellStyled>
                  )}
                  <TableCellStyled>{formatCurrency(price)}</TableCellStyled>
                  {showPriceToUser && (
                    <>
                      <TableCellStyled>
                        <BadgeDiscount>{`${discount}%`}</BadgeDiscount>
                      </TableCellStyled>
                      <TableCellStyled>
                        {formatCurrency(finalPrice)}
                      </TableCellStyled>
                    </>
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ProductVariants;
