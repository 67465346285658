import {
  Accordion,
  AccordionSummary,
  Box,
  Button,
  inputLabelClasses,
  outlinedInputClasses,
  Select,
  Tab,
  Tabs,
  tabsClasses,
  TextField,
  Typography,
} from '@mui/material';
import { createTheme, styled } from '@mui/material/styles';
import { CUSTOM_THEME_COLORS } from './variables';

export let theme = createTheme();
theme = createTheme({
  shape: {
    borderRadius: 0,
  },

  palette: {
    primary: {
      main: '#000',
      dark: CUSTOM_THEME_COLORS.primary,
    },
    secondary: {
      main: '#fff',
      dark: '#fff',
    },
    text: {
      primary: '#000',
      // disabled: '#ff0000',
    },
    error: {
      main: CUSTOM_THEME_COLORS.red,
    },
    danger: {
      main: CUSTOM_THEME_COLORS.red,
    },
    action: {
      // disabled: '#ff0000',
      // activatedOpacity: 1,
      // active: string;
      // hover: string;
      // hoverOpacity: number;
      // selected: string;
      // selectedOpacity: number;
      // disabled: string;
      // disabledOpacity: number;
      // disabledBackground: string;
      // focus: string;
      // focusOpacity: number;
      // activatedOpacity: number;
    },
  },

  typography: {
    fontFamily: ['Roboto', 'sans-serif'].join(','),
    fontSize: 13,

    h1: {
      fontSize: '1.8rem',
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h3: {
      fontSize: '1.4rem',
      fontWeight: 900,
      textTransform: 'uppercase',
    },
    h4: {
      fontSize: '1.1rem',
      fontWeight: 700,
    },
    h5: {
      fontSize: '1.1rem',
      fontWeight: 700,
    },
    small: {
      fontSize: '0.8rem',
    },
    xsmall: {
      fontSize: '0.7rem',
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      transition: 'color 200ms ease',
      '&:hover': {
        color: CUSTOM_THEME_COLORS.primary,
      },
    },
  },

  components: {
    MuiCssBaseline: {
      styleOverrides: ({ breakpoints }) => ({
        body: {
          [breakpoints.down(breakpoints.values.md)]: {
            fontSize: '0.9rem',
          },
        },
      }),
    },
    MuiTypography: {
      styleOverrides: {
        caption: {
          color: theme.palette.text.primary,
        },
      },
      defaultProps: {
        variantMapping: {
          small: 'p',
          xsmall: 'p',
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true, // No more ripple, on the whole application 💣!
      },
    },
    MuiButton: {
      styleOverrides: {
        contained: {
          textTransform: 'none',
        },
      },
    },
    MuiCircularProgress: {
      styleOverrides: {
        colorPrimary: {
          color: CUSTOM_THEME_COLORS.primary,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        standard: {
          backgroundColor: CUSTOM_THEME_COLORS.primary,
          color: '#fff',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: CUSTOM_THEME_COLORS.darkGray,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginTop: theme.spacing(2.5),
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          '&.Mui-checked': {
            color: CUSTOM_THEME_COLORS.primary,
          },
        },
        track: {
          backgroundColor: CUSTOM_THEME_COLORS.primary,
          '.Mui-checked.Mui-checked + &': {
            backgroundColor: CUSTOM_THEME_COLORS.primary,
          },
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          '&.Mui-completed': {
            '.MuiStepLabel-label, .MuiStepIcon-root': {
              color: CUSTOM_THEME_COLORS.primary,
            },
          },
          '.Mui-active': {
            '&.MuiStepLabel-label, &.MuiStepIcon-root': {
              color: CUSTOM_THEME_COLORS.primary,
            },
          },
          '.MuiStepLabel-root': {
            '.MuiStepLabel-label.MuiStepLabel-alternativeLabel': {
              marginTop: theme.spacing(1.5),
            },
            '.MuiStepIcon-text': {
              fontWeight: 500,
            },
          },
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          '.MuiTableCell-head': {
            fontWeight: 700,
            backgroundColor: CUSTOM_THEME_COLORS.gray,
            textAlign: 'center',
            padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
          },
          '.MuiTableCell-body': {
            textAlign: 'center',
          },
        },
      },
    },
  },

  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1536,
    },
  },
});

const MainContainer = styled(Box, { label: 'MainContainer' })(() => ({
  paddingTop: theme.spacing(5),
  paddingBottom: theme.spacing(5),
}));

const StyledOrderSummaryBox = styled(Box)(() => ({
  margin: theme.spacing(1),
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
}));

const StyledTextButton = styled(Button)(() => ({
  textTransform: 'none',
}));

const StyledTextfield = styled(TextField)(() => ({
  backgroundColor: '#fff',
  marginTop: theme.spacing(2),
}));

const StyledFormTextfield = styled(TextField)(() => ({
  variant: 'outlined',
  backgroundColor: '#fff',
}));

const StyledSearchTextfield = styled(TextField)(() => ({
  [`& .${outlinedInputClasses.notchedOutline},
  .${outlinedInputClasses.input},
  .${inputLabelClasses.outlined},
  &:hover .${inputLabelClasses.outlined},
  .${inputLabelClasses.outlined}.${inputLabelClasses.focused}`]: {
    border: 0,
    color: '#fff',
  },
}));

const StyledMyAccountTabs = styled(Tabs)(() => ({
  [`& .${tabsClasses.indicator}`]: {
    left: 0,
  },
}));

const StyledSidebarTab = styled(Tab)(() => ({
  textTransform: 'none',
  fontSize: '0.85rem',
  fontWeight: 700,
  marginRight: 'auto',
  width: '100%',
  maxWidth: '100%',
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
}));

const StyledTableSelect = styled(Select)(() => ({
  fontSize: '0.9em',
  [`& .${outlinedInputClasses.notchedOutline}`]: {
    border: 0,
  },
}));

const StyledFormInputLabel = styled(Typography)(() => ({
  fontWeight: 700,
}));

const StyledOrderSummaryText = styled(Typography)(() => ({
  fontSize: '12px',
  fontWeight: 700,
}));

const StyledAccordion = styled((props) => (
  <Accordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(() => ({
  backgroundColor: 'transparent',
  '&:before': {
    display: 'none',
  },
}));

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  borderBottom: `1px solid ${theme.palette.divider}`,
  paddingLeft: 0,
  paddingRight: 0,
}));

// template

const HeaderAccountButton = styled(Button)(() => ({
  textTransform: 'none',
  minWidth: 'initial',
}));

const HeaderAccountButtonText = styled(Typography)(() => ({
  display: { xs: 'none', md: 'flex' },
}));

const CategoryButton = styled(Button)(() => ({
  position: 'initial',
  textTransform: 'none',
  fontWeight: '700',
  color: 'white',
  '&:hover': {
    backgroundColor: CUSTOM_THEME_COLORS.lightPrimary,
  },
}));

//  PRODUCT LIST
const ProductsFilters = styled(Accordion)(() => ({
  [`& .MuiAccordionSummary-root.Mui-expanded`]: {
    minHeight: '48px',
  },
  [`& .MuiAccordionSummary-content.Mui-expanded`]: {
    margin: 0,
  },
  [`& .MuiAccordionDetails-root`]: {
    paddingTop: theme.spacing(2),
    [`.MuiFormGroup-root`]: {
      gap: '4px',
    },
  },
}));

const CategoriesButton = styled(Box)(({ active }) => ({
  textDecoration: 'none',
  color: 'black',
  fontWeight: 700,
  fontSize: '0.85em',
  textTransform: 'uppercase',
  borderBottom: `2px solid ${active ? 'black' : 'transparent'}`,
  [`&:hover`]: {
    borderColor: 'black',
  },
}));

const SubCategoriesButton = styled(Box)(() => ({
  display: 'flex',
  textDecoration: 'none',
  color: 'black',
  fontSize: '0.85em',
  [`&:hover`]: {
    color: CUSTOM_THEME_COLORS.primary,
  },
}));

export {
  StyledTextButton,
  StyledTextfield,
  StyledSidebarTab,
  StyledSearchTextfield,
  StyledTableSelect,
  StyledFormInputLabel,
  StyledOrderSummaryBox,
  StyledOrderSummaryText,
  StyledFormTextfield,
  StyledAccordion,
  StyledAccordionSummary,
  // template
  HeaderAccountButton,
  HeaderAccountButtonText,
  CategoryButton,
  MainContainer,
  StyledMyAccountTabs,
  //  reusable components
  ProductsFilters,
  //  CATEGORIES MENU
  CategoriesButton,
  SubCategoriesButton,
};
