import React from 'react';
import ProductListCard from '../ProductListCard';

const ListView = ({ products, showPriceToUser, customerId }) => {
  return products.map((product, index) => (
    <ProductListCard
      key={`${product.title}-${index}`}
      product={{ ...product }}
      showPriceToUser={showPriceToUser}
      customerId={customerId}
      showStockAvailability={true}
    />
  ));
};

export default ListView;
