import { Container, Typography } from '@mui/material';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const Highlights = ({ highlights }) => {
  return (
    <Container sx={{ paddingY: { xs: 3, md: 6 } }}>
      <Typography
        variant='h2'
        marginBottom={1}
      >
        As nossas novidades
      </Typography>
      <Typography marginBottom={2}>Aqui poderá encontrar um conjunto de produtos associados ao seu produto.</Typography>
      <ProductsCarousel products={highlights} />
    </Container>
  );
};

export default Highlights;
