import { AppBar, Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import { useSelector } from 'react-redux';
import { SMALL_DEVICES_BREAKPOINT } from '../../variables';
import AccountLinks from './AccountLinks/AccountLinks';
import ClientSelector from './ClientSelector';
import NavBar from './NavBar/NavBar';
import SearchBar from './SearchBar';
import CompanyLogo from '../CompanyLogo';
import NotificationBar from '../NotificationBar/NotificationBar';

const Header = () => {
  const XSMALL_DEVICES = useMediaQuery(`(max-width:${SMALL_DEVICES_BREAKPOINT})`);

  const role = useSelector((state) => state.user.role);

  return (
    <AppBar
      position='sticky'
      color='inherit'
      elevation={0}
    >
      {role && role !== 'customer' && <ClientSelector />}
      <NotificationBar />
      <Container>
        <Grid
          container
          justifyContent='space-between'
          alignItems='center'
          sx={{ paddingY: 1.5 }}
        >
          <CompanyLogo />
          {XSMALL_DEVICES ? (
            <>
              <AccountLinks />
              <SearchBar />
            </>
          ) : (
            <>
              <SearchBar />
              <AccountLinks />
            </>
          )}
        </Grid>
      </Container>
      <NavBar />
    </AppBar>
  );
};

export default Header;
