import { Box, Typography } from '@mui/material';
import React from 'react';
import BudgetRequestForm from '../components/BudgetRequestForm';
import StoreLocations from '../components/StoreLocations';
import STORES from '../constants/stores';
import { CONTACT_US } from '../variables';

const ContactsPage = () => {
  return (
    <Box sx={{ padding: 5 }}>
      <Typography
        variant='h1'
        sx={{ textAlign: 'center', marginBottom: 3 }}
      >
        {CONTACT_US.title}
      </Typography>
      <Typography sx={{ whiteSpace: 'pre-line', textAlign: 'center', marginBottom: 2 }}>{CONTACT_US.subtitle}</Typography>
      <StoreLocations stores={STORES} />
      <BudgetRequestForm />
    </Box>
  );
};

export default ContactsPage;
