import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapCategory } from './mapApiInterface';

//  GET

function getPage(name) {
  return axios.get(`${API_URL}/pages/${name}`, getHeaders()).then(({ data: { page } }) => page);
}

function getCategories() {
  return axios.get(`${API_URL}/categories`, getHeaders()).then(({ data }) =>
    data.categories.map((category) => {
      return mapCategory(category);
    })
  );
}

function getBrands(id) {
  return axios.get(id ? `${API_URL}/brands/${id}` : `${API_URL}/brands`, getHeaders()).then(({ data: brands }) => brands);
}

// POST

export { getCategories, getPage, getBrands };
