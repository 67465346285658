import { Box, Tab, Tabs, useMediaQuery } from '@mui/material';
import React, { useState } from 'react';
import { SMALL_DEVICES_BREAKPOINT, PRODUCT_INFO_TABS, CUSTOM_THEME_COLORS } from '../../../variables';
import ProductDetailsTable from './TechTable';
import VideoCard from './VideoCard';
import { useTheme } from '@mui/material/styles';

const ProductTabs = ({ video, params, _datasheet, SMALL_DEVICES }) => {
  const theme = useTheme();
  const XSMALL_DEVICES = useMediaQuery(`(max-width:${SMALL_DEVICES_BREAKPOINT})`);
  const [activeTab, setActiveTab] = useState(0);

  return (
    <Box marginTop={6}>
      <Tabs
        value={activeTab}
        textColor='secondary'
        variant={XSMALL_DEVICES && PRODUCT_INFO_TABS.length > 2 ? 'scrollable' : null}
        scrollButtons={XSMALL_DEVICES && PRODUCT_INFO_TABS.length > 2 && true}
        allowScrollButtonsMobile
      >
        {PRODUCT_INFO_TABS.map((tab, index) => (
          <Tab
            key={`product-info-tab-${tab}`}
            value={index}
            label={tab}
            title={tab}
            onClick={() => setActiveTab(index)}
            sx={{
              textTransform: 'none',
              marginRight: 0.5,
              backgroundColor: activeTab === index ? CUSTOM_THEME_COLORS.primary : '#F6F8FB',
              fontWeight: 500,
              borderTopLeftRadius: theme.shape.borderRadius,
              borderTopRightRadius: theme.shape.borderRadius,
              letterSpacing: '0.25px',
            }}
          />
        ))}
      </Tabs>

      {activeTab === 0 && <ProductDetailsTable params={params} />}

      {activeTab === 1 && (
        <VideoCard
          video={video}
          SMALL_DEVICES={SMALL_DEVICES}
        />
      )}

      {/* {activeTab === 2 && <Downloads datasheet={datasheet} />} */}
    </Box>
  );
};

export default ProductTabs;
