import { Badge, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { useRef, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiShoppingCart, FiUser } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../../api/login';
import { formatTextToTitleCase } from '../../../constants/utils';
import { setCart, setCartTotal } from '../../../state/cartSlice';
import { setRole, setSelectedClient, setSellerInfo } from '../../../state/userSlice';
import { StyledTextButton } from '../../../styles';
import { ACCOUNT_LINKS } from '../../../variables';
import UserMenu from './UserMenu';

const AccountLinks = () => {
  const user = useSelector((state) => state.user);
  const cart = useSelector((state) => state.cart?.cart);
  const userName = user.personalInfo.name;
  const role = user.role;
  const [cookie, _setCookie, removeCookie] = useCookies(['userToken']);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const userRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  function handleLogout() {
    setIsMenuOpen(false);
    setIsLoading(true);

    logout().then(() => {
      //  remove user token
      removeCookie('userToken', { path: '/' });
      //  reset seller info
      dispatch(setSellerInfo({}));
      //  reset selected client
      dispatch(setSelectedClient(null));
      //  reset total cart
      dispatch(setCartTotal(0));
      //  reset cart items
      dispatch(setCart([]));
      //  reset user role
      dispatch(setRole(''));
      setIsLoading(false);
      navigate('/');
    });
  }

  return (
    <Grid2
      component='nav'
      sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1.5,
      }}
    >
      <StyledTextButton
        startIcon={<FiShoppingCart size={24} />}
        component={Link}
        to={cookie.userToken ? '/carrinho' : '/login'}
        title='Carrinho'
        sx={{
          minWidth: 'initial',
          gap: 1,
          '& .MuiButton-startIcon': {
            marginRight: 0,
          },
        }}
      >
        {cart.length !== 0 && (
          <Badge
            badgeContent={cart.reduce((prev, item) => prev + item.quantity, 0)}
            sx={{ top: '-10px' }}
          />
        )}
        {/* ) : (
          <Typography sx={{ display: { xs: 'none', md: 'flex' } }}>Carrinho</Typography>
        )} */}
      </StyledTextButton>

      {!cookie.userToken || isLoading ? (
        <StyledTextButton
          startIcon={<FiUser size={24} />}
          component={Link}
          to='/login'
          title='Login'
        >
          <Typography>Login</Typography>
        </StyledTextButton>
      ) : (
        <>
          <StyledTextButton
            startIcon={<FiUser size={24} />}
            ref={userRef}
            onClick={() => setIsMenuOpen(true)}
            title='A minha conta'
          >
            <Typography
              sx={{
                fontWeight: 500,
              }}
            >
              {formatTextToTitleCase(userName.split(' ')[0])}
            </Typography>
          </StyledTextButton>
          <UserMenu
            isMenuOpen={isMenuOpen}
            setIsMenuOpen={setIsMenuOpen}
            userRef={userRef}
            accountLinks={ACCOUNT_LINKS?.[role]}
            handleLogout={handleLogout}
          />
        </>
      )}
    </Grid2>
  );
};

export default AccountLinks;
