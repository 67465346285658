import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import AccessForm from './AccessForm';

const AccountAccess = () => {
  const personalInfo = useSelector((state) => state.user.personalInfo);

  if (!personalInfo) return null;

  return (
    <Box
      sx={{
        flex: 3,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Typography
        marginBottom={1}
        sx={{ fontWeight: 700, textTransform: 'uppercase' }}
      >
        Dados de Acesso à Conta
      </Typography>

      <Box marginBottom={1.5}>
        <Typography variant='small'>
          Se deseja alterar a palavra-passe associada a esta conta, por favor preencha os seguintes campos. <br /> A
          palavra-passe atual é pedida por razões de segurança.
        </Typography>
      </Box>

      <AccessForm />
    </Box>
  );
};

export default AccountAccess;
