import STORES from './constants/stores';

const API_URL = `${process.env.REACT_APP_API_URL}/store`;
const IMAGES_PATH = '/public/images/';
const NOT_FOUND_ROUTE = 'pagina-nao-encontrada';

//  CUSTOM COLORS
const CUSTOM_THEME_COLORS = {
  primary: '#376fa4',
  lightPrimary: '#6489AC',
  gray: '#ddd',
  lightGray: '#f7f7f7',
  darkGray: '#383838',
  red: '#ff0000',
};

// info
const COMPANY_NAME = 'Geonatlife';
const COMPANY_INFO = {
  name: 'Geonatlife',
  address: {
    street:
      'Parque Empresarial Vialonga - Granja, 25 Casal do Bagulho - Granja de Alpriate',
    zipCode: '2625-607 Vialonga',
    city: '',
  },
  phone: '+351 914 936 495',
  fax: '',
  email: 'info@geonatlife.com',
  social: {
    linkedin: '',
    facebook: 'https://www.facebook.com/geonatlife',
    instagram: 'https://www.instagram.com/geonatlife/',
  },
};

// login
const LOGIN_FORM_TITLE = 'Clientes Profissionais';

const LOGIN_PASS_RECOVERY_TEXT = 'Esqueceu-se da palavra-passe?';

const LOGIN_REGISTER_TEXT = 'Ainda não tem conta de cliente?';

const PASS_RECOVERY_TITLE = 'Recuperar Palavra-Passe';

const PASS_RECOVERY_TEXT =
  'Após recuperar a palavra-passe irá receber uma nova no seu email.';

const USER_REGISTER_TITLE = 'Registo de Novo Utilizador';

const REGISTER_SUCCESS_TEXT =
  'O seu registo foi efetuado com sucesso. Receberá um email com a aprovação da conta.';

const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo'];
// const PRODUCT_INFO_TABS = ['Detalhes Técnicos', 'Vídeo', 'Downloads'];

const CONTACT_US = {
  title: `Contactos`,
  subtitle: `Precisa de ajuda? Temos ao seu dispor ${STORES.length} ${
    STORES.length <= 1 ? 'loja' : 'lojas'
  }. \nEncontre a ${COMPANY_INFO.name} mais perto de si.`,
};

// breakpoints
const SMALL_DEVICES_BREAKPOINT = '600px';
const MEDIUM_DEVICES_BREAKPOINT = '900px';
const LARGE_DEVICES_BREAKPOINT = '1200px';
const XLARGE_DEVICES_BREAKPOINT = '1536px';

//  PAGES SLUGS
const PAGES_SLUGS = {
  search: '/pesquisa',
  news: '/novidades',
  campaigns: '/campanhas',
  brands: '/marcas',
  contacts: '/contactos',
  cart: '/carrinho',
  account: '/conta',
  login: '/login',
  page: '/page',
  products: '/produtos',
  recoverPassword: 'recuperar-palavra-passe',
  createUser: 'registo-utilizador',
  institutional: {
    aboutUs: '/page/quem-somos',
    termsAndConditions: '/page/termos-e-condicoes',
    privacyPolicy: '/page/politica-de-privacidade',
  },
  myAccount: {
    info: `dados-pessoais`,
    accessData: 'dados-acesso',
    receipts: 'conta-corrente',
    shoppingList: 'lista-compras',
    orders: 'encomendas',
    productsHistory: 'visualizacoes',
    users: 'utilizadores',
    addresses: 'moradas',
  },
};

const ACCOUNT_LINKS = {
  customer: [
    {
      title: 'Dados Pessoais',
      url: PAGES_SLUGS.myAccount.info,
      userMenu: true,
    },
    {
      title: 'Dados de Acesso',
      url: PAGES_SLUGS.myAccount.accessData,
      userMenu: false,
    },
    {
      title: 'Moradas',
      url: PAGES_SLUGS.myAccount.addresses,
      userMenu: true,
    },
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'Listas de compras',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: true,
    },
    {
      title: 'Visualizações',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: false,
    },
  ],
  seller: [
    {
      title: 'Encomendas',
      url: PAGES_SLUGS.myAccount.orders,
      userMenu: true,
    },
    {
      title: 'Conta Corrente',
      url: PAGES_SLUGS.myAccount.receipts,
      userMenu: true,
    },
    {
      title: 'Listas de compras',
      url: PAGES_SLUGS.myAccount.shoppingList,
      userMenu: true,
    },
    {
      title: 'Visualizações',
      url: PAGES_SLUGS.myAccount.productsHistory,
      userMenu: true,
    },
  ],
};

// navbar
const NAVBAR_LINKS = [
  { title: 'Airolube', url: '', highlighted: false, categoryButton: false },
  { title: 'Less', url: '', highlighted: false, categoryButton: false },
  { title: 'Riskless', url: '', highlighted: false, categoryButton: false },
  { title: 'Sponser', url: '', highlighted: false, categoryButton: false },
  { title: '7IDP', url: '', highlighted: false, categoryButton: false },
  { title: 'Tag Metals', url: '', highlighted: false, categoryButton: false },
  { title: 'Royal', url: '', highlighted: false, categoryButton: false },
  { title: 'Esigrips', url: '', highlighted: false, categoryButton: false },
  {
    title: 'Kids Ride Shotgun',
    url: '',
    highlighted: false,
    categoryButton: false,
  },
  // { title: 'Campanhas', url: '/campanhas', highlighted: true, categoryButton: false },
  // { title: 'Novidades', url: '/novidades', highlighted: false, categoryButton: false },
  // { title: 'Marcas', url: '/marcas', highlighted: false, categoryButton: false },
  // { title: 'Quem somos', url: PAGES_SLUGS.aboutUs, highlighted: false, categoryButton: false },
  // { title: 'Contactos', url: PAGES_SLUGS.contacts, highlighted: false, categoryButton: false },
];

const DELIVERY_METHODS = {
  STORE: 'deliveryAtStore',
  ADDRESS: 'deliveryAtAddress',
};

export {
  API_URL,
  NOT_FOUND_ROUTE,
  COMPANY_NAME,
  COMPANY_INFO,
  LOGIN_FORM_TITLE,
  LOGIN_PASS_RECOVERY_TEXT,
  LOGIN_REGISTER_TEXT,
  PASS_RECOVERY_TITLE,
  PASS_RECOVERY_TEXT,
  USER_REGISTER_TITLE,
  REGISTER_SUCCESS_TEXT,
  SMALL_DEVICES_BREAKPOINT,
  MEDIUM_DEVICES_BREAKPOINT,
  LARGE_DEVICES_BREAKPOINT,
  XLARGE_DEVICES_BREAKPOINT,
  ACCOUNT_LINKS,
  NAVBAR_LINKS,
  CONTACT_US,
  IMAGES_PATH,
  PAGES_SLUGS,
  PRODUCT_INFO_TABS,
  CUSTOM_THEME_COLORS,
  DELIVERY_METHODS,
};
