import { Box, Popover } from '@mui/material';
import React, { useRef, useState } from 'react';
import { FiFilter } from 'react-icons/fi';
import { StyledTextButton } from '../styles';
import ProductFilter from './ProductFilter';

const ProductFilterPopover = ({ availableFilters, activeFilters }) => {
  const filterRef = useRef(null);

  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  return (
    <Box>
      <StyledTextButton
        variant='contained'
        color='secondary'
        ref={filterRef}
        onClick={() => setIsPopoverOpen(true)}
        disableElevation
        startIcon={<FiFilter size={16} />}
        sx={{ fontWeight: 700, fontSize: '0.9em' }}
      >
        Filtrar
      </StyledTextButton>

      <Popover
        open={isPopoverOpen}
        anchorEl={filterRef.current}
        onClose={() => setIsPopoverOpen(false)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        sx={{ overflowY: 'scroll' }}
        PaperProps={{
          style: { maxHeight: '60%' },
        }}
      >
        <Box>
          {availableFilters.map((filter) => (
            <ProductFilter
              key={filter.id}
              type={filter.id}
              filter={filter}
              activeFilters={activeFilters}
            />
          ))}
        </Box>
      </Popover>
    </Box>
  );
};

export default ProductFilterPopover;
