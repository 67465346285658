import { LoadingButton } from '@mui/lab';
import { Box, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { recoverPassword } from '../../api/login';
import { StyledFormInputLabel } from '../../styles';
import { PASS_RECOVERY_TEXT, PASS_RECOVERY_TITLE } from '../../variables';
import PopupNotification from '../PopupNotification/PopupNotification';

const PasswordRecovery = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [recoveryEmail, setRecoveryEmail] = useState('');
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    successMessage: 'Foi enviada uma nova password para o email indicado',
  });

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!recoveryEmail) return;

    recoverPassword(recoveryEmail)
      .then(() => {
        setNotificationProps((prevState) => ({ ...prevState, isOpened: true, type: 'success' }));
        setIsLoading(false);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status >= 400) {
            setNotificationProps((prevState) => ({ ...prevState, isOpened: true, type: 'error', errorMessage: msg }));
          }
          if (status === 500) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: 'O email inserido não é válido',
            }));
          }
          setIsLoading(false);
        }
      );
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: { xs: '80vw', md: '30vw' },
          marginTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box
          sx={{
            marginBottom: 1,
          }}
        >
          <StyledFormInputLabel sx={{ textAlign: 'center', marginBottom: 1 }}>{PASS_RECOVERY_TITLE}</StyledFormInputLabel>
          <Typography
            variant='small'
            sx={{ textAlign: 'center' }}
          >
            {PASS_RECOVERY_TEXT}
          </Typography>
        </Box>

        <TextField
          fullWidth
          label='Email'
          type='email'
          required
          value={recoveryEmail}
          onChange={(e) => setRecoveryEmail(e.target.value)}
        />

        <LoadingButton
          variant='contained'
          type='submit'
          title='Submeter'
          sx={{
            marginTop: 3,
          }}
          loading={isLoading}
        >
          Submeter
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default PasswordRecovery;
