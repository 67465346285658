import { Box, Button, Container, Typography } from '@mui/material';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import CartProgressIndicator from '../components/Cart/CartProgressIndicator';
import CartSummary from '../components/Cart/FirstStepSummary/CartSummary';
import OrderConfirmation from '../components/Cart/OrderConfirmation';
import OrderDetails from '../components/Cart/SecondStepOrderDetails/OrderDetails';
import { MainContainer } from '../styles';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';

const CartPage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const user = useSelector((state) => state.user);
  const cartObject = useSelector((state) => state.cart);
  const { cart, total: cartTotalPrice } = cartObject;

  function handleActiveStep(step) {
    const element = document.querySelector('.app');

    element.scrollTo({ top: 0, behavior: 'smooth' });

    setActiveStep(step);
  }

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          <Grid xs={12}>
            {cart.length === 0 && activeStep !== 2 ? (
              <Box sx={{ textAlign: 'center' }}>
                <Typography>Não tem produtos no seu carrinho.</Typography>
                <Button
                  title='Voltar à homepage'
                  variant='contained'
                  component={Link}
                  to='/'
                  sx={{ marginTop: 2 }}
                >
                  Voltar à homepage
                </Button>
              </Box>
            ) : (
              <>
                <CartProgressIndicator activeStep={activeStep} />

                {activeStep === 0 && (
                  <CartSummary
                    totalPrice={cartTotalPrice}
                    setActiveStep={handleActiveStep}
                    customerId={user.selectedClient.id}
                  />
                )}

                {activeStep === 1 && (
                  <OrderDetails
                    cart={cartObject}
                    setActiveStep={handleActiveStep}
                  />
                )}

                {activeStep === 2 && <OrderConfirmation />}
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default CartPage;
