import { Box, Typography } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTextButton } from '../../../styles';
import QuantitySelector from './QuantitySelector';
import AddShoppingListButton from '../../ShoppingLists/AddShoppingListButton';
import { CUSTOM_THEME_COLORS } from '../../../variables';

const ProductCheckout = ({
  product,
  hasVariants,
  addToCart,
  productVariantDetails,
  updatedProductQuantity,
  setUpdatedProductQuantity,
}) => {
  return (
    <>
      {!hasVariants && (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            marginTop: 3,
          }}
          gap={{ xs: 5, md: 10 }}
        >
          <Box>
            <Typography
              marginBottom={1}
              fontWeight={700}
            >
              Preço
            </Typography>

            <Box>
              {product.onSale ? (
                <Box
                  sx={{
                    display: 'flex',
                    gap: 1.5,
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 500,
                      textDecoration: 'line-through',
                    }}
                  >
                    {formatCurrency(product.price)}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: '1.3rem',
                      fontWeight: 600,
                      color: CUSTOM_THEME_COLORS.primary,
                    }}
                  >
                    {formatCurrency(product.salePrice)}
                  </Typography>
                </Box>
              ) : (
                <Typography
                  sx={{
                    fontSize: '1.3rem',
                    fontWeight: 600,
                  }}
                >
                  {formatCurrency(product.price)}
                </Typography>
              )}
            </Box>
          </Box>

          {product.stock > 0 && (
            <Box>
              <Typography
                marginBottom={1}
                fontWeight={700}
              >
                Quantidade
              </Typography>

              <QuantitySelector
                product={updatedProductQuantity}
                setUpdatedProductQuantity={setUpdatedProductQuantity}
              />
            </Box>
          )}
        </Box>
      )}

      <Box
        sx={{
          width: '100%',
          marginTop: 3,
          display: 'flex',
          gap: 6,
          justifyContent: hasVariants ? 'space-between' : 'flex-start',
        }}
      >
        {hasVariants && (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
              gap: 1,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <Typography variant='xsmall'>Total Unidades</Typography>
              <Typography
                component='span'
                sx={{
                  fontWeight: 900,
                  color: CUSTOM_THEME_COLORS.primary,
                }}
              >
                {productVariantDetails.totalUnits}
              </Typography>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1.5,
              }}
            >
              <Typography variant='xsmall'>Total</Typography>
              <Typography
                component='span'
                sx={{
                  fontSize: '1.5rem',
                  fontWeight: 900,
                  color: CUSTOM_THEME_COLORS.primary,
                }}
              >
                {formatCurrency(productVariantDetails.total)}
              </Typography>
            </Box>
          </Box>
        )}

        <Box
          sx={{
            display: 'inline-flex',
            alignItems: 'flex-start',
            gap: 2,
          }}
        >
          <AddShoppingListButton product={product} />

          <StyledTextButton
            variant='contained'
            title='Adicionar ao carrinho'
            onClick={() => addToCart()}
            disabled={
              product.stock <= 0 ||
              (hasVariants && productVariantDetails.totalUnits === 0)
            }
          >
            Adicionar ao Carrinho
          </StyledTextButton>
        </Box>
      </Box>
    </>
  );
};

export default ProductCheckout;
