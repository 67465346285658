import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { formatCurrency } from '../../../constants/utils';
import { StyledTextButton } from '../../../styles';

const DiscountSelectorModal = ({ isApplyDiscountOpen, setIsApplyDiscountOpen, updateTotalWithDiscount, product }) => {
  const [discount, setDiscount] = useState(product.discount ?? 0);
  const productPrice = product.onSale ? product.salePrice : product.price;
  const priceWithDiscount = parseFloat(Math.fround(product.price - (product.price * discount) / 100).toFixed(2));

  function decrement() {
    if (discount < 1) return;
    setDiscount(discount - 1);
  }

  function increment() {
    if (discount === 100) return;
    setDiscount(discount + 1);
  }

  return (
    <Dialog
      open={isApplyDiscountOpen}
      onClose={() => setIsApplyDiscountOpen(false)}
      PaperProps={{
        sx: {
          maxWidth: { xs: '100%', md: '500px' },
          margin: { xs: 2, md: 4 },
        },
      }}
    >
      <DialogTitle sx={{ fontWeight: 700, paddingY: 3 }}>Adicionar desconto</DialogTitle>

      <DialogContent sx={{ paddingBottom: 3 }}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Preço</TableCell>
                <TableCell>Preço c/ desconto</TableCell>
                <TableCell>Desconto (%)</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Typography sx={{ textDecoration: 'line-through' }}>{formatCurrency(productPrice)}</Typography>
                </TableCell>
                <TableCell>
                  <Typography sx={{ fontWeight: 600, color: 'danger.main' }}>{formatCurrency(priceWithDiscount)}</Typography>
                </TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: 0.5,
                    }}
                  >
                    <Button
                      sx={{ minWidth: '30px' }}
                      onClick={decrement}
                      disabled={discount === 0}
                    >
                      -
                    </Button>

                    <Typography
                      sx={{ minWidth: '40px', textAlign: 'center', fontWeight: 600 }}
                    >{`${discount} %`}</Typography>

                    <Button
                      sx={{ minWidth: '30px' }}
                      onClick={increment}
                      disabled={discount === 99}
                    >
                      +
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        <DialogActions
          sx={{
            padding: 0,
            marginTop: 3,
          }}
        >
          <StyledTextButton
            title='title'
            variant='contained'
            color='secondary'
            onClick={() => setIsApplyDiscountOpen(false)}
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            title='Aplicar desconto'
            variant='contained'
            onClick={() => {
              setIsApplyDiscountOpen(false);
              updateTotalWithDiscount(product, discount, priceWithDiscount);
            }}
          >
            Aplicar desconto
          </StyledTextButton>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

export default DiscountSelectorModal;
