import { Box, Container, Popper } from '@mui/material';
import React, { useState } from 'react';
import Grid from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';
import { CategoriesButton, SubCategoriesButton } from '../../../../styles';

const CategoriesMenu = ({ setIsCategoriesMenuOpen, isCategoriesMenuOpen, anchorEl, categoriesTree }) => {
  const [subCategoriesTree, setSubCategoriesTree] = useState([]);
  const [activeCategory, setActiveCategory] = useState('');

  return (
    <Popper
      open={isCategoriesMenuOpen}
      anchorEl={anchorEl}
      disablePortal
      keepMounted
      placement='bottom-start'
      sx={{
        width: '100%',
        boxShadow: '0px 3px 5px 1px rgba(0,0,0,0.1)',
        backgroundColor: 'white',
        cursor: 'default',
        paddingY: 3,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box sx={{ display: 'flex', gap: 5 }}>
              {categoriesTree.map((category) => (
                <CategoriesButton
                  component={Link}
                  key={`category-${category?.id}`}
                  to={`/${category?.url}`}
                  title={category?.title}
                  active={activeCategory === category?.id ? true : undefined}
                  onMouseEnter={() => {
                    setSubCategoriesTree(category?.subcategories);
                    setActiveCategory(category?.id);
                  }}
                  onClick={() => {
                    setIsCategoriesMenuOpen(false);
                  }}
                >
                  {category?.title}
                </CategoriesButton>
              ))}
            </Box>
          </Grid>
          {subCategoriesTree.length !== 0 && (
            <Grid xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: 4,
                  gap: 4,
                }}
              >
                {subCategoriesTree.map((subCategory) => (
                  <Box
                    key={`subcategory-${subCategory?.id}`}
                  >
                    <SubCategoriesButton
                      component={Link}
                      to={subCategory?.url}
                      title={subCategory?.title}
                      onClick={() => {
                        setIsCategoriesMenuOpen(false);
                      }}
                      sx={{
                        fontWeight: 600,
                        paddingBottom: 0.5,
                      }}
                    >
                      {subCategory?.title}
                    </SubCategoriesButton>
                    {subCategory?.productTypes.map((productType) => (
                      <SubCategoriesButton
                        key={`productType-${productType?.id}`}
                        component={Link}
                        to={productType?.url}
                        title={productType?.title}
                        onClick={() => {
                          setIsCategoriesMenuOpen(false);
                        }}
                        sx={{
                          paddingY: 0.5,
                        }}
                      >
                        {productType?.title}
                      </SubCategoriesButton>
                    ))}
                  </Box>
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
    </Popper>
  );
};

export default CategoriesMenu;
