import { Box } from '@mui/material';
import { Outlet } from 'react-router-dom';
import loginImage from '../assets/login-image.jpg';
import CompanyLogo from '../components/CompanyLogo';
import { COMPANY_NAME } from '../variables';

const LoginPage = () => {
  return (
    <Box
      sx={{
        overflow: 'hidden',
        height: 'calc(100vh - 16px)',
        display: 'grid',
        alignItems: 'center',
        gridAutoFlow: 'column',
        gridAutoColumns: '1fr',
      }}
    >
      <Box
        component='img'
        src={loginImage}
        alt={COMPANY_NAME}
        sx={{
          display: { xs: 'none', md: 'block' },
          width: '100%',
          objectFit: 'cover',
          aspectRatio: '2/3',
          overflow: 'hidden',
        }}
      />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <Box sx={{ textAlign: 'center' }}>
          <CompanyLogo width={{ xs: '90px' }} />
        </Box>
        <Outlet />
      </Box>
    </Box>
  );
};

export default LoginPage;
