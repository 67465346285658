import { AccordionDetails, Box, Link } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { StyledAccordion, StyledAccordionSummary, StyledFormInputLabel } from '../../styles';
import { COMPANY_INFO } from '../../variables';
import { FiPlus } from 'react-icons/fi';

const FooterMobileAccordion = () => {
  const {
    address: { street, zipCode, city },
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (e, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Box sx={{ margin: '30px auto 0 auto' }}>
      <StyledAccordion expanded={expanded === 'aboutUs'} onChange={handleChange('aboutUs')}>
        <StyledAccordionSummary aria-controls='aboutUs-content' id='aboutUs-header' expandIcon={<FiPlus />}>
          <StyledFormInputLabel>Mais sobre a {COMPANY_INFO.name}</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: '10px',
            },
          }}
        >
          <Typography
            component={RouterLink}
            to='/quemsomos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Quem Somos
          </Typography>

          <Typography>
            <Link href='' underline='none' target='_blank' rel='noreferrer'>
              Catálogos
            </Link>
          </Typography>

          <Typography
            component={RouterLink}
            to='/contactos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Contactos
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'customerSupport'} onChange={handleChange('customerSupport')}>
        <StyledAccordionSummary aria-controls='customerSupport-content' id='customerSupport-header' expandIcon={<FiPlus />}>
          <StyledFormInputLabel>Apoio ao cliente</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: '10px',
            },
          }}
        >
          <Typography
            component={RouterLink}
            to='/termos'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Termos e Condições
          </Typography>

          <Typography
            component={RouterLink}
            to='/privacidade'
            sx={{
              display: 'block',
              textDecoration: 'none',
              color: 'inherit',
            }}
          >
            Política de Privacidade
          </Typography>

          <Typography>
            <Link href='https://www.livroreclamacoes.pt/Inicio/' target='_blank' rel='noreferrer' underline='none'>
              Livro de Reclamações
            </Link>
          </Typography>
        </AccordionDetails>
      </StyledAccordion>

      <StyledAccordion expanded={expanded === 'contactUs'} onChange={handleChange('contactUs')}>
        <StyledAccordionSummary aria-controls='contactUs-content' id='contactUs-header' expandIcon={<FiPlus />}>
          <StyledFormInputLabel>Contacte-nos</StyledFormInputLabel>
        </StyledAccordionSummary>

        <AccordionDetails
          sx={{
            '& .MuiTypography-root': {
              marginY: '10px',
            },
          }}
        >
          <Typography>
            {street} {zipCode} {city}
          </Typography>

          <Typography>
            <strong>Telf.</strong> {phone}
          </Typography>

          <Typography>
            <strong>Fax.</strong> {fax}
          </Typography>

          <Typography>
            <strong>Email.</strong> {email}
          </Typography>
        </AccordionDetails>
      </StyledAccordion>
    </Box>
  );
};

export default FooterMobileAccordion;
