import { Box, TextField } from '@mui/material';
import { useOutletContext } from 'react-router-dom';

const CompanyInfo = ({ data }) => {
  const [XSMALL_DEVICES] = useOutletContext();
  const { name, street, zipCode, city, country, taxNumber } = data;

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: 2,
        }}
      >
        <TextField
          label='Nome'
          defaultValue={name}
          disabled
          sx={{
            flex: 1,
          }}
        />

        <TextField
          label='Morada'
          defaultValue={street}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: XSMALL_DEVICES && 'column',
          gap: 2,
        }}
      >
        <TextField
          label='Código Postal'
          defaultValue={zipCode}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label='Localidade'
          defaultValue={city}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label='País'
          defaultValue={country}
          disabled
          sx={{ flex: 1 }}
        />

        <TextField
          label='NIF'
          defaultValue={taxNumber}
          disabled
          sx={{ flex: 1 }}
        />
      </Box>
    </>
  );
};

export default CompanyInfo;
