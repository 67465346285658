import { Box, Typography } from '@mui/material';
import React from 'react';
import ProductsCarousel from '../Carousel/ProductsCarousel';

const ProductRelated = ({ related }) => {
  return (
    <Box marginTop={{ xs: 6, md: 8 }}>
      <Typography
        variant='h3'
        marginBottom={2}
      >
        Produtos Relacionados
      </Typography>
      <ProductsCarousel products={related} />
    </Box>
  );
};

export default ProductRelated;
