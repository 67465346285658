import { Menu } from '@mui/material';
import React, { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { BiMenu } from 'react-icons/bi';
import { CategoryButton } from '../../../styles';
import PresentationalCategoriesMenuItem from './CategoriesMenu/PresentationalCategoriesMenuItem';

const CategoriesMobileNavBar = () => {
  const categoriesRef = useRef(null);
  const [isCategoriesOpen, setIsCategoriesOpen] = useState(false);
  const categories = useSelector((state) => state.categories.categories);

  return (
    <>
      <CategoryButton
        ref={categoriesRef}
        startIcon={<BiMenu />}
        onClick={() => setIsCategoriesOpen(true)}
      >
        Produtos
      </CategoryButton>

      {isCategoriesOpen && (
        <Menu
          anchorEl={categoriesRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          keepMounted
          open={isCategoriesOpen}
          onClose={() => setIsCategoriesOpen(false)}
          MenuListProps={{
            style: { backgroundColor: '#eaeaea' },
          }}
          PaperProps={{
            sx: {
              borderRadius: 0,
            },
          }}
        >
          {categories.map((category, index) => (
            <PresentationalCategoriesMenuItem
              key={`${category}-${index}`}
              index={index}
              category={category}
              setIsCategoriesOpen={setIsCategoriesOpen}
            />
          ))}
        </Menu>
      )}
    </>
  );
};

export default CategoriesMobileNavBar;
