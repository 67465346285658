import { Box, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { ImFacebook } from 'react-icons/im';
import { FaInstagram, FaLinkedinIn } from 'react-icons/fa';
import { COMPANY_INFO, CUSTOM_THEME_COLORS } from '../../variables';

const SocialMedia = () => {
  return (
    <Grid
      item
      xs={12}
      md={3}
    >
      <Typography
        variant='h4'
        marginBottom={1.5}
        sx={{
          textTransform: 'uppercase',
          fontSize: '1rem',
          color: CUSTOM_THEME_COLORS.primary,
        }}
      >
        Siga-nos
      </Typography>

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconContext.Provider value={{ style: { color: '#000000DE' } }}>
          {COMPANY_INFO.social.facebook && (
            <Link
              href={COMPANY_INFO.social.facebook}
              target='_blank'
              title='Facebook'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginLeft: { xs: 0.75, md: 0 },
                marginRight: 0.75,
                '&:hover': {
                  opacity: 0.75,
                },
              }}
            >
              <ImFacebook size={16} />
            </Link>
          )}

          {COMPANY_INFO.social.linkedin && (
            <Link
              href={COMPANY_INFO.social.linkedin}
              target='_blank'
              title='Linkedin'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginX: 0.75,
                '&:hover': {
                  opacity: 0.75,
                },
              }}
            >
              <FaLinkedinIn size={18} />
            </Link>
          )}
          {COMPANY_INFO.social.instagram && (
            <Link
              href={COMPANY_INFO.social.instagram}
              target='_blank'
              title='Instagram'
              rel='noreferrer'
              underline='none'
              sx={{
                display: 'flex',
                marginX: 0.75,
                '&:hover': {
                  opacity: 0.75,
                },
              }}
            >
              <FaInstagram size={18} />
            </Link>
          )}
        </IconContext.Provider>
      </Box>
    </Grid>
  );
};

export default SocialMedia;
