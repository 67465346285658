import { Box } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import { StyledMyAccountTabs, StyledSidebarTab } from '../../styles';
import { ACCOUNT_LINKS } from '../../variables';

const UserAccountNavbar = ({ SMALL_DEVICES, userRole }) => {
  const menuItems = ACCOUNT_LINKS?.[userRole];

  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');
  const currentLocation = splitLocation[splitLocation.length - 1];

  return (
    <Box sx={{ marginBottom: { xs: 4, md: 0 } }}>
      {menuItems && (
        <StyledMyAccountTabs
          orientation={SMALL_DEVICES ? 'horizontal' : 'vertical'}
          variant={SMALL_DEVICES ? 'scrollable' : 'standard'}
          value={currentLocation}
          scrollButtons
          allowScrollButtonsMobile
        >
          {menuItems.map(({ title, url }) => (
            <StyledSidebarTab
              key={`myAccountNavbar-${url}`}
              value={url}
              label={title}
              title={title}
              component={Link}
              to={url}
              sx={{
                alignItems: SMALL_DEVICES ? 'center' : 'start',
              }}
            />
          ))}
        </StyledMyAccountTabs>
      )}
    </Box>
  );
};

export default UserAccountNavbar;
