import { Grid, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';
import { COMPANY_INFO, CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../variables';

const AboutUs = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={1.5}
        sx={{ textTransform: 'uppercase', fontSize: '1rem', color: CUSTOM_THEME_COLORS.primary }}
      >
        Sobre a {COMPANY_INFO.name}
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={PAGES_SLUGS.institutional.aboutUs}
        title='Quem Somos'
        sx={{
          display: 'block',
        }}
      >
        Quem Somos
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={PAGES_SLUGS.news}
        title='Novidades'
        sx={{
          display: 'block',
        }}
      >
        Novidades
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={PAGES_SLUGS.brands}
        title='Marcas'
        sx={{
          display: 'block',
        }}
      >
        Marcas
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={Link}
        to={PAGES_SLUGS.contacts}
        title='Contactos'
        sx={{
          display: 'block',
        }}
      >
        Contactos
      </Typography>
    </Grid>
  );
};

export default AboutUs;
