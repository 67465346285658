import { useNavigate, useParams } from 'react-router-dom';
import { NOT_FOUND_ROUTE } from '../../variables';
import { useState } from 'react';
import { useEffect } from 'react';
import { getPage } from '../../api';
import InstitucionalPage from '../../routes/InstitucionalPage';
import Loading from '../Loading';
import TermsPage from '../../routes/TermsPage';

const InstitucionalPages = () => {
  const { slug } = useParams();
  const navigate = useNavigate();
  const [page, setPage] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  let slugPage = slug;

  const slugs = {
    aboutUs: {
      backofficeSlug: 'quemsomos',
      finalSlug: 'quem-somos',
    },
    termsAndConditions: {
      backofficeSlug: 'termos',
      finalSlug: 'termos-e-condicoes',
    },
    privacyPolicy: {
      backofficeSlug: 'politica',
      finalSlug: 'politica-de-privacidade',
    },
  };

  if (slugPage === slugs.aboutUs.finalSlug) {
    slugPage = slugs.aboutUs.backofficeSlug;
  } else if (slugPage === slugs.termsAndConditions.finalSlug) {
    slugPage = slugs.termsAndConditions.backofficeSlug;
  } else if (slugPage === slugs.privacyPolicy.finalSlug) {
    slugPage = slugs.privacyPolicy.backofficeSlug;
  }

  useEffect(() => {
    setIsLoading(true);

    getPage(slugPage)
      .then((pageContent) => {
        setPage(pageContent);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        setIsLoading(false);
        // If page wasn't created in backoffce redirect as it makes no sense to
        // show a page without information to display
        if (status === 422) navigate(`/${NOT_FOUND_ROUTE}`);
      });
  }, [slugPage, navigate]);

  if (isLoading) {
    return <Loading />;
  } else {
    switch (slug) {
      case slugs.aboutUs.finalSlug:
        return <InstitucionalPage props={page} />;
      case slugs.termsAndConditions.finalSlug:
      case slugs.privacyPolicy.finalSlug:
        return <TermsPage props={page} />;
      default:
        break;
    }
  }
};

export default InstitucionalPages;
