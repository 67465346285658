import { Dialog, DialogActions, DialogContent, useMediaQuery } from '@mui/material';
import { StyledTextButton } from '../../../../styles';
import { MEDIUM_DEVICES_BREAKPOINT } from '../../../../variables';

const EliminateFromCartButton = ({ onDelete, feedback }) => {
  const [openFeedback, setOpenFeedback] = feedback;

  const SMALL_DEVICES = useMediaQuery(`(max-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  return (
    <>
      <StyledTextButton
        onClick={() => {
          setOpenFeedback(true);
        }}
        sx={{ color: 'danger.main' }}
        title='Eliminar produto'
      >
        Eliminar
      </StyledTextButton>

      <Dialog
        PaperProps={{
          sx: {
            width: SMALL_DEVICES ? '80%' : '50%',
            padding: '20px',
          },
        }}
        open={openFeedback}
        onClose={() => setOpenFeedback(false)}
      >
        <DialogContent sx={{ fontWeight: 700 }}>Eliminar produto?</DialogContent>

        <DialogActions>
          <StyledTextButton
            variant='contained'
            color='secondary'
            onClick={() => setOpenFeedback(false)}
            title='Cancelar'
          >
            Cancelar
          </StyledTextButton>

          <StyledTextButton
            variant='contained'
            onClick={() => {
              onDelete();
              setOpenFeedback(false);
            }}
            title='Eliminar'
          >
            Eliminar
          </StyledTextButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default EliminateFromCartButton;
