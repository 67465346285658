import { Box, Card, CardMedia, Container, Typography } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { StyledTextButton } from '../../styles';

const WelcomeImages = ({ mainBanner }) => {
  const { title, description, buttonTitle, buttonUrl, image } = mainBanner;

  return (
    <Container sx={{ paddingBottom: { xs: 3, md: 6 } }}>
      <Grid2
        container
        spacing={{ xs: 1, md: 2 }}
      >
        {/* main banner */}
        <Grid2 xs={12}>
          <Card
            sx={{
              position: 'relative',
              boxShadow: 'none',
            }}
          >
            <CardMedia
              component='img'
              image={image}
              height='400px'
              sx={{ filter: ' brightness(70%)' }}
            />

            <Box
              component='div'
              sx={{
                position: 'absolute',
                bottom: '30px',
                left: '30px',
                maxWidth: '50%',
              }}
            >
              <Typography
                marginBottom={1}
                variant='h2'
                color='white'
              >
                {title}
              </Typography>

              <Typography
                marginBottom={2}
                sx={{
                  color: '#fff',
                }}
              >
                {description}
              </Typography>

              <StyledTextButton
                size='small'
                href={buttonUrl}
                sx={{
                  marginTop: '10px',
                  color: '#fff',
                  border: '2px solid #fff',
                  paddingX: '20px',
                }}
              >
                {buttonTitle}
              </StyledTextButton>
            </Box>
          </Card>
        </Grid2>
      </Grid2>
    </Container>
  );
};

export default WelcomeImages;
