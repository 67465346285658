import Grid from '@mui/material/Unstable_Grid2';
import React from 'react';
import ProductCard from '../ProductCard';

const GridView = ({ products, showPriceToUser }) => {
  return (
    <Grid
      container
      spacing={2}
      sx={{
        padding: 0,
      }}
    >
      {products.map((product, index) => (
        <Grid
          key={`${product.reference}-${index}`}
          xs={6}
          sm={4}
          lg={3}
        >
          <ProductCard
            product={{ ...product }}
            showPrice={showPriceToUser}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default GridView;
