import { LoadingButton } from '@mui/lab';
import { Box, TextField } from '@mui/material';
import { useState } from 'react';
import { useCookies } from 'react-cookie';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../api/login';
import { getCart, getShoppingLists, setCustomer } from '../../api/user';
import { setCart, setCartId, setCartTotal } from '../../state/cartSlice';
import {
  setAddresses,
  setCompanyInfo,
  setPersonalInfo,
  setRole,
  setSelectedClient,
  setSellerInfo,
  setUserId,
} from '../../state/userSlice';
import PopupNotification from '../PopupNotification/PopupNotification';
import { setShoppingLists } from '../../state/shoppingListsSlice';

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
  });
  const [userData, setUserData] = useState({
    email: '',
    password: '',
  });

  const [_cookies, setCookie, _removeCookie] = useCookies(['userToken']);

  function handleChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({
      ...values,
      [name]: value,
    }));
  }

  function handleSubmit(e) {
    e.preventDefault();

    setIsLoading(true);

    if (!userData.email || !userData.password) {
      setIsLoading(false);

      return;
    }

    login(userData)
      .then(({ token, user, expiresIn }) => {
        const expiresInSeconds = parseInt(expiresIn.charAt(0)) * 24 * 60 * 60;
        const userRole = user.user_role.toLowerCase();
        let sellerCustomers = null;

        //  get user token
        setCookie('userToken', token, {
          path: '/',
          maxAge: expiresInSeconds,
        });

        dispatch(setCompanyInfo(user.companyInfo));
        dispatch(setPersonalInfo(user.personalInfo));
        dispatch(setAddresses(user.addresses));
        dispatch(setRole(userRole));
        dispatch(setUserId(user.id));

        const sellerInfo = {
          name: user.personalInfo.name,
          email: user.personalInfo.email,
          clients: user.customers,
        };

        //  if it is a 'customer'
        if (userRole === 'customer') {
          sellerInfo.paymentTime = user.payment_deadlines;
        }

        dispatch(setSellerInfo(sellerInfo));

        //  if it is a 'seller'
        if (userRole === 'seller') {
          const firstCustomer = user?.customers?.[0];
          dispatch(setSelectedClient(firstCustomer?.companyInfo?.name));

          //  set first customer as an active
          setCustomer({ customerId: firstCustomer?.id });

          if (user?.customers.length !== 0) {
            sellerCustomers = user?.customers;
          }
        }

        if (
          userRole === 'customer' ||
          (userRole === 'seller' && sellerCustomers !== null)
        ) {
          setIsLoading(true);

          //  get cart items
          getCart(
            userRole === 'customer'
              ? user?.cart_id
              : sellerCustomers?.[0]?.cart_id
          )
            .then(({ cart_items, total, _id }) => {
              dispatch(setCart(cart_items));
              dispatch(setCartId(_id));
              dispatch(setCartTotal(total));

              return (
                //  get shipping lists
                getShoppingLists()
                  .then((lists) => {
                    dispatch(setShoppingLists(lists));

                    if (isLoading) setIsLoading(false);
                  })
                  .catch(({ response: { status } }) => {
                    if (status === 401) navigate('/login');
                  })
              );
            })
            .catch(({ response: { status } }) => {
              if (status === 401) navigate('/login');
            });
        }

        return navigate('/', { replace: true });
      })
      .catch(({ response: { status, data } }) => {
        const { msg } = data;
        if (status >= 400) {
          setNotificationProps((prevState) => ({
            ...prevState,
            isOpened: true,
            type: 'error',
            errorMessage: msg,
          }));
        }
        setIsLoading(false);
      });
  }

  return (
    <>
      <Box
        component='form'
        onSubmit={handleSubmit}
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <TextField
          fullWidth
          label='Introduza o seu email'
          type='email'
          name='email'
          required
          value={userData.email}
          onChange={handleChange}
        />

        <TextField
          fullWidth
          label='Introduza a sua palavra-passe'
          type='password'
          name='password'
          required
          value={userData.password}
          onChange={handleChange}
        />

        <LoadingButton
          variant='contained'
          type='submit'
          title='Iniciar sessão'
          sx={{
            marginTop: 3,
          }}
          loading={isLoading}
        >
          Iniciar sessão
        </LoadingButton>
      </Box>

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default LoginForm;
