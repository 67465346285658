import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';
import React from 'react';
import STORES from '../../../../constants/stores';

const DeliveryAtStore = ({ store, onStoreChange }) => {
  return (
    <FormControl
      margin='normal'
      sx={{ marginY: 2 }}
    >
      <InputLabel>Selecione o armazém</InputLabel>

      <Select
        label='Selecione o armazém'
        value={store}
        onChange={onStoreChange}
      >
        {STORES.map(({ title }) => (
          <MenuItem
            key={title}
            value={title}
          >
            {title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default DeliveryAtStore;
