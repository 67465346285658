import { Box, Button, Container, useMediaQuery } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getCategories } from '../../../api';
import { setCategories } from '../../../state/categoriesSlice';
import { CategoryButton } from '../../../styles';
import {
  CUSTOM_THEME_COLORS,
  NAVBAR_LINKS,
  SMALL_DEVICES_BREAKPOINT,
} from '../../../variables';
import CategoriesMenu from './CategoriesMenu/CategoriesMenu';
import CategoriesMobileNavBar from './CategoriesMobileNavBar';
import kebabCase from 'lodash/kebabCase';

const NavBar = () => {
  const XSMALL_DEVICES = useMediaQuery(
    `(max-width:${SMALL_DEVICES_BREAKPOINT})`
  );

  const [isCategoriesMenuOpen, setIsCategoriesMenuOpen] = useState(false);
  const [categoriesTree, setCategoriesTree] = useState([]);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const categoriesMenuRef = useRef(null);
  const navRef = useRef(null);

  useEffect(() => {
    getCategories()
      .then((newCategories) => {
        dispatch(setCategories(newCategories));
        setCategoriesTree(newCategories);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <Box
      ref={navRef}
      sx={{
        position: 'relative',
        backgroundColor: CUSTOM_THEME_COLORS.primary,
      }}
    >
      <Container>
        <Grid container>
          <Grid xs={12}>
            <Box component='nav'>
              {XSMALL_DEVICES ? (
                <CategoriesMobileNavBar />
              ) : (
                <Box
                  display='flex'
                  sx={{
                    width: '100%',
                  }}
                >
                  {NAVBAR_LINKS.map(({ title }) => {
                    return (
                      <Box
                        key={title}
                        ref={categoriesMenuRef}
                        onMouseEnter={() => {
                          // setIsCategoriesMenuOpen(true);
                        }}
                        onMouseLeave={() => {
                          // setIsCategoriesMenuOpen(false);
                        }}
                        sx={{
                          '&:first-of-type': {
                            marginLeft: -3,
                          },
                        }}
                      >
                        <CategoryButton
                          component={Button}
                          title={title}
                          disableRipple
                          href={`/${kebabCase(title)}`}
                          sx={{
                            paddingLeft: 3,
                            paddingRight: 3,
                            textTransform: 'uppercase',
                            letterSpacing: '0.6px',
                            // backgroundColor: isCategoriesMenuOpen ? 'white' : 'transparent',
                          }}
                        >
                          {title}
                        </CategoryButton>
                        <CategoriesMenu
                          setIsCategoriesMenuOpen={setIsCategoriesMenuOpen}
                          isCategoriesMenuOpen={isCategoriesMenuOpen}
                          anchorEl={navRef.current}
                          categoriesTree={categoriesTree}
                        />
                      </Box>
                    );
                  })}
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default NavBar;
