import { Box, Typography } from '@mui/material';
import ProductTitleFormatter from '../../../ProductTitleFormatter/ProductTitleFormatter';
import { imageOnError } from '../../../../helpers';
import { Link } from 'react-router-dom';

const SummaryProductInfo = ({ id, images, title, reference, brand }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{ marginRight: '20px', display: ' flex', alignItems: ' center', justifyContent: 'center' }}
        component={Link}
        to={`/produtos/${id}`}
        title={title}
      >
        <Box
          component='img'
          src={images[0]}
          onError={imageOnError}
          width='100px'
          alt={title}
          sx={{ objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <Box
        sx={{
          textAlign: 'left',
        }}
      >
        <Typography
          variant='link'
          sx={{ fontSize: '.9rem', fontWeight: 700 }}
          component={Link}
          to={`/produtos/${id}`}
          title={title}
        >
          <ProductTitleFormatter
            title={title}
            reference={reference}
          />
        </Typography>
        <Box
          sx={{
            marginTop: 0.5,
          }}
        >
          <Typography variant='small'>{`Ref: ${reference}`}</Typography>
          <Typography variant='small'>{`Marca: ${brand}`}</Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default SummaryProductInfo;
