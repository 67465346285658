import React from 'react';
// import ReactElasticCarousel from 'react-elastic-carousel';
import { useCookies } from 'react-cookie';
import { Navigation } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import ProductCard from '../ProductCard';
import './index.css';

const ProductsCarousel = ({
  props,
  products,
  breakpoints = {
    0: {
      slidesPerView: 1,
    },
    400: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 3,
    },
    800: {
      slidesPerView: 4,
    },
    1000: {
      slidesPerView: 5,
    },
  },
}) => {
  const [cookie] = useCookies(['userToken']);

  return (
    <Swiper
      {...props}
      spaceBetween={26}
      breakpoints={breakpoints}
      navigation
      modules={[Navigation]}
      className='products-carousel'
    >
      {products.map((product, index) => (
        <SwiperSlide key={`${product.reference}-${index}`}>
          <ProductCard
            product={product}
            showPrice={!!cookie.userToken}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default ProductsCarousel;
