import { Box, Typography } from '@mui/material';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../../variables';
// import ProductStock from '../../ProductStock';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../../../api/products';
import {
  setCartTotal,
  setDiscount,
  updateCart,
} from '../../../state/cartSlice.js';
import ProductStock from '../../ProductStock';
import ProductTitleFormatter from '../../ProductTitleFormatter/ProductTitleFormatter';
import ProductCheckout from './ProductCheckout';
import ProductVariants from './ProductVariants';
import PopupNotification from '../../PopupNotification/PopupNotification';
import { useState } from 'react';
import { useEffect } from 'react';

const ProductDetails = ({ product, showPriceToUser, customerId }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { title, brand, description, reference, stock, variants } = product;
  const hasVariants = variants.length !== 0;

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: '',
    errorMessage: '',
  });

  const [productVariantDetails, setProductVariantDetails] = useState({
    totalUnits: 0,
    total: 0,
  });
  const [productsForCart, setProductsForCart] = useState([]);
  const [updatedProductQuantity, setUpdatedProductQuantity] = useState(
    !hasVariants
      ? {
          ...product,
          quantity: 1,
        }
      : undefined
  );

  useEffect(() => {
    if (hasVariants) {
      //  calculate total units
      const totalUnits = productsForCart.reduce(
        (acc, product) => acc + product.quantity,
        0
      );

      //  calculate total cost
      const total = productsForCart.reduce(
        (acc, product) =>
          acc + (product.salePrice || product.price) * product.quantity,
        0
      );

      setProductVariantDetails({ totalUnits: totalUnits, total: total });
    }
  }, [productsForCart, hasVariants]);

  useEffect(() => {
    if (hasVariants) {
      //  PRODUCT WITH VARIANTS

      if (updatedProductQuantity) {
        //  check if product already exists
        const productAlreadyExists = productsForCart.find(
          (product) => product.reference === updatedProductQuantity.reference
        );

        //  check if product already exists
        if (productAlreadyExists) {
          setProductsForCart((prevState) =>
            prevState.reduce((acc, product) => {
              //  product with same reference
              product.reference === updatedProductQuantity.reference
                ? //  update product quantity with 'quantity !== 0'
                  updatedProductQuantity.quantity !== 0 &&
                  acc.push({
                    ...product,
                    quantity: updatedProductQuantity.quantity,
                  })
                : acc.push(product);
              return acc;
            }, [])
          );
        } else {
          //  add new product to array
          setProductsForCart([...productsForCart, updatedProductQuantity]);
        }
      }
    } else {
      //  SIMPLE PRODUCT

      //  check IF array isn't empty
      if (productsForCart.length !== 0) {
        //  check if product already exists
        const productAlreadyExists = productsForCart.find(
          (product) => product.reference === updatedProductQuantity.reference
        );

        //  check if product already exists
        if (productAlreadyExists) {
          //  update quantity of an existing product
          setProductsForCart((prevState) => [
            ...prevState.map((product) =>
              //  product with same reference
              product.reference === updatedProductQuantity.reference
                ? { ...product, quantity: updatedProductQuantity.quantity }
                : product
            ),
          ]);
        } else {
          //  add new product to array
          setProductsForCart([...productsForCart, updatedProductQuantity]);
        }
      } else {
        setProductsForCart([updatedProductQuantity]);
      }
    }
  }, [updatedProductQuantity, productsForCart, hasVariants]);

  const addToCart = () => {
    console.log('addToCart', productsForCart);

    // return;

    addProductsToCart(productsForCart, customerId)
      .then(({ cart_items, total_discount, total }) => {
        dispatch(updateCart(cart_items));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(total));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
          successMessage: 'Produto adicionado ao carrinho',
        }));
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <>
      <Box
        sx={{
          flex: 1,
        }}
      >
        {brand && (
          <Typography
            marginBottom={1}
            sx={{ fontSize: '.8em', fontWeight: 300 }}
          >
            {brand}
          </Typography>
        )}

        <Typography variant='h1'>
          <ProductTitleFormatter
            title={title}
            reference={reference}
          />
        </Typography>

        {reference && (
          <Typography
            marginTop={1}
            sx={{ fontSize: '.9em' }}
          >{`Ref: ${reference}`}</Typography>
        )}

        <Box sx={{ marginTop: 1 }}>
          <ProductStock stock={stock} />
        </Box>

        {description && (
          <Typography
            marginTop={2}
            sx={{ whiteSpace: 'pre-line' }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}

        {hasVariants && (
          <ProductVariants
            variants={variants}
            showPriceToUser={showPriceToUser}
            updatedProductQuantity={updatedProductQuantity}
            setUpdatedProductQuantity={setUpdatedProductQuantity}
          />
        )}

        {showPriceToUser ? (
          <ProductCheckout
            product={product}
            hasVariants={hasVariants}
            addToCart={addToCart}
            productVariantDetails={productVariantDetails}
            updatedProductQuantity={updatedProductQuantity}
            setUpdatedProductQuantity={setUpdatedProductQuantity}
          />
        ) : (
          <Box sx={{ marginTop: 3 }}>
            <Typography
              dangerouslySetInnerHTML={{
                __html: `Por favor, faça <a href=${PAGES_SLUGS.login} title='Login' style="color:${CUSTOM_THEME_COLORS.primary}; font-weight:600">Login</a> para visualizar os preços dos produtos.`,
              }}
            />
          </Box>
        )}
      </Box>
      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </>
  );
};

export default ProductDetails;
