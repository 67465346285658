import { ListItemIcon, Menu, MenuItem, Typography } from '@mui/material';
import React, { useRef, useState } from 'react';
import { TiPlus } from 'react-icons/ti';
import PresentationalSubcategoriesMenuItem from './PresentationalSubcategoriesMenuItem';

const PresentationalCategoriesMenuItem = ({ setIsCategoriesOpen, category, index }) => {
  const { subcategories, title } = category;

  const [isSubcategoriesOpen, setIsSubcategoriesOpen] = useState(false);

  const subcategoriesRef = useRef([]);

  return (
    <>
      <MenuItem
        onClick={() => setIsSubcategoriesOpen(true)}
        ref={(el) => (subcategoriesRef.current[index] = el)}
        sx={{
          paddingRight: 0,
          '&:hover': { backgroundColor: '#fff' },
        }}
      >
        <Typography
          sx={{
            fontSize: '14px',
            marginRight: '20px',
            fontWeight: 600,
            color: '#666',
          }}
        >
          {title.toUpperCase()}
        </Typography>

        <ListItemIcon sx={{ marginLeft: 'auto' }}>
          <TiPlus />
        </ListItemIcon>
      </MenuItem>

      <Menu
        anchorEl={subcategoriesRef.current[index]}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        keepMounted
        open={isSubcategoriesOpen}
        onClose={() => setIsSubcategoriesOpen(false)}
        PaperProps={{
          onMouseLeave: () => setIsSubcategoriesOpen(false),
        }}
        sx={{ top: '-8px' }}
      >
        {subcategories.map((subcategory, index) => (
          <PresentationalSubcategoriesMenuItem
            key={`${subcategory}-${index}`}
            category={category}
            setIsCategoriesOpen={setIsCategoriesOpen}
            subcategory={subcategory}
            setIsSubcategoriesOpen={setIsSubcategoriesOpen}
          />
        ))}
      </Menu>
    </>
  );
};

export default PresentationalCategoriesMenuItem;
