import {
  Box,
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { deleteProductFromShoppingList } from '../../api/user';
import { formatCurrency } from '../../constants/utils';
import { setShoppingLists } from '../../state/shoppingListsSlice';
import SummaryProductInfo from '../Cart/FirstStepSummary/SummaryTable/SummaryProductInfo';
import PopupNotification from '../PopupNotification/PopupNotification';
import { FiTrash2 } from 'react-icons/fi';

const ShoppingListProducts = ({ index, shoppingLists }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    successMessage: 'Produto removido',
    errorMessage: '',
  });

  const { _id: id, products } = shoppingLists?.[index];

  const handleDeleteProductFromShoppingList = (productId) => {
    deleteProductFromShoppingList(id, productId)
      .then((lists) => {
        dispatch(setShoppingLists(lists));

        setNotificationProps((prevState) => ({
          ...prevState,
          isOpened: true,
          type: 'success',
        }));
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  };

  return (
    <Box
      sx={{
        marginTop: 4,
      }}
    >
      {shoppingLists?.[index]?.products.length !== 0 ? (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>
                  Detalhes
                </TableCell>
                <TableCell sx={{ minWidth: { xs: '150px', md: 'initial' } }}>
                  Preço
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>

            <TableBody>
              {Array.isArray(products) &&
                products.map((product) => {
                  const {
                    _id: id,
                    title,
                    brand,
                    reference,
                    price,
                    salePrice,
                    onSale,
                    images,
                  } = product;
                  const pricePerUnit = onSale ? salePrice : price;

                  return (
                    <TableRow
                      key={`${reference}-${id}`}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <TableCell>
                        {/* designation */}
                        <SummaryProductInfo
                          id={id}
                          images={images}
                          title={title}
                          reference={reference}
                          brand={brand}
                        />
                      </TableCell>

                      <TableCell>
                        {/* your price */}
                        {onSale ? (
                          <Box
                            sx={{
                              display: 'flex',
                              justifyContent: 'center',
                              gap: 1,
                            }}
                          >
                            <Typography sx={{ textDecoration: 'line-through' }}>
                              {formatCurrency(price)}
                            </Typography>
                            <Typography
                              sx={{ fontWeight: 600, color: 'danger.main' }}
                            >
                              {formatCurrency(pricePerUnit)}
                            </Typography>
                          </Box>
                        ) : (
                          <Typography>
                            {formatCurrency(pricePerUnit)}
                          </Typography>
                        )}
                      </TableCell>

                      <TableCell>
                        <Button
                          onClick={() =>
                            handleDeleteProductFromShoppingList(id)
                          }
                          title='Remover'
                          sx={{
                            minWidth: 'initial',
                          }}
                        >
                          <FiTrash2 size={18} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <Typography>Não tem produtos associados a esta lista.</Typography>
      )}

      <PopupNotification
        notificationProps={notificationProps}
        setNotificationProps={setNotificationProps}
      />
    </Box>
  );
};

export default ShoppingListProducts;
