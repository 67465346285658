import { Autocomplete, Box, IconButton, MenuItem, TextField, useTheme } from '@mui/material';
import debounce from 'lodash/debounce';
import { useEffect, useState } from 'react';
import { FiSearch } from 'react-icons/fi';
import { Link, createSearchParams, useNavigate } from 'react-router-dom';
import { getProducts } from '../../api/products';
import { CUSTOM_THEME_COLORS } from '../../variables';

const SEARCH_TEXT = 'Ver todos os resultados de ';
const SEARCH_ID = 'search';

const SearchBar = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [dropdownProducts, setDropdownProducts] = useState([]);
  const [shownProducts, setShownProducts] = useState([]);
  const [inputValue, setInputValue] = useState('');

  const getOptionsDebounced = debounce(async (input) => {
    const newProducts = await getProducts({ input }).then(({ displayedProducts }) => displayedProducts);

    setDropdownProducts(newProducts);
  }, 500);

  useEffect(() => {
    const filteredProducts = dropdownProducts
      .filter(({ title, reference }) => {
        return (
          title.toLowerCase().includes(inputValue.toLowerCase()) ||
          reference.toLowerCase().includes(inputValue.toLowerCase())
        );
      })
      .slice(0, 4);

    const mappedProducts = filteredProducts.map(({ _id, title }) => ({
      label: title,
      id: _id,
    }));

    const searchFor = { label: `${SEARCH_TEXT}${inputValue}`, id: SEARCH_ID };

    const shownProducts = inputValue ? [...mappedProducts, searchFor] : [];

    setShownProducts(shownProducts);
  }, [dropdownProducts, inputValue]);

  function handleInputChange(e, newInputValue, reason) {
    if (reason === 'reset') {
      setInputValue('');
      return;
    }

    const replacedInputValue = newInputValue.replace(SEARCH_TEXT, '');

    setInputValue(replacedInputValue);

    if (replacedInputValue.length < 4) return;

    getOptionsDebounced(replacedInputValue);
  }

  function navigateToSearchPage(id) {
    if (id === SEARCH_ID && inputValue) {
      navigate({
        pathname: '/pesquisa',
        search: createSearchParams({
          query: inputValue,
        }).toString(),
      });
    }

    setInputValue('');
  }

  function handleClickOnItem(id) {
    navigateToSearchPage(id);
  }

  function handleKeyPress(e) {
    if (e.key === 'Enter') navigateToSearchPage(SEARCH_ID);
  }

  function handleIconClick() {
    navigateToSearchPage(SEARCH_ID);
  }

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '400px',
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
      }}
    >
      <Autocomplete
        options={shownProducts}
        disableClearable
        freeSolo
        fullWidth
        inputValue={inputValue}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label='O que está à procura?'
            onKeyPress={handleKeyPress}
            sx={{ color: 'black', marginTop: 0 }}
          />
        )}
        renderOption={(params, { id, label }) => (
          <MenuItem
            {...params}
            key={id}
            component={id !== SEARCH_ID ? Link : null}
            to={`produtos/${id}`}
            onClickCapture={() => handleClickOnItem(id)}
          >
            {label}
          </MenuItem>
        )}
        sx={{
          backgroundColor: CUSTOM_THEME_COLORS.lightGray,
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: CUSTOM_THEME_COLORS.gray,
          },
        }}
      />
      <IconButton
        onClick={handleIconClick}
        title='Pesquisar'
        sx={{
          position: 'absolute',
          right: theme.spacing(1),
          color: 'black',
        }}
      >
        <FiSearch size={20} />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
