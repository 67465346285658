import { useState } from 'react';
import { Box, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { removeProductFromCart, updateCartProducts } from '../../../../api/checkout';
import {
  removeFromCart,
  removeQuantityFromCartItem,
  setCart,
  setCartTotal,
  updateCart,
  setDiscount,
} from '../../../../state/cartSlice';
import EliminateFromCartButton from './EliminateFromCartButton';

const SummaryQuantity = ({ quantity, id, reference, cart, customerId }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const [openFeedback, setOpenFeedback] = useState(false);

  function decrement(reference) {
    const productQuantityDecreased = cart.map(({ quantity, ...cartItem }) => ({
      ...cartItem,
      quantity: cartItem.reference === reference ? quantity - 1 : quantity,
    }));

    // When decrementing if the quantity is 1, trigger the delete product from
    // cart action with an alert
    const productToRemove = productQuantityDecreased.find((product) => product.reference === reference);
    if (productToRemove.quantity === 0) {
      setOpenFeedback(true);
      return;
    }

    dispatch(removeQuantityFromCartItem(productQuantityDecreased));

    updateCartProducts(productQuantityDecreased, customerId)
      .then(({ total_discount, total: totalPrice }) => {
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  function increment(reference) {
    const productQuantityIncreased = cart.map(({ quantity, ...cartItem }) => ({
      ...cartItem,
      quantity: cartItem.reference === reference ? quantity + 1 : quantity,
    }));

    dispatch(updateCart(productQuantityIncreased));

    updateCartProducts(productQuantityIncreased, customerId)
      .then(({ total_discount, total: totalPrice }) => {
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  function handleProductDelete(id, reference) {
    dispatch(removeFromCart({ id, reference }));

    removeProductFromCart(reference, customerId)
      .then(({ cart_items: cart, total: totalPrice, total_discount }) => {
        dispatch(setCart(cart));
        dispatch(setDiscount(total_discount));
        dispatch(setCartTotal(totalPrice));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 0.5,
        }}
      >
        <Button
          title='Diminuir quantidade'
          sx={{ minWidth: '30px' }}
          onClick={() => decrement(reference)}
        >
          -
        </Button>

        <Box
          component='span'
          sx={{ minWidth: '20px' }}
        >
          {quantity}
        </Box>

        <Button
          title='Aumentar quantidade'
          sx={{ minWidth: '30px' }}
          onClick={() => increment(reference)}
        >
          +
        </Button>
      </Box>
      <EliminateFromCartButton
        onDelete={() => handleProductDelete(id, reference)}
        feedback={[openFeedback, setOpenFeedback]}
      />
    </Box>
  );
};

export default SummaryQuantity;
