import {
  Box,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Icon,
  Switch,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import capitalize from 'lodash/capitalize';
import { useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { FiGrid, FiList } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { getProducts } from '../api/products';
import Loading from '../components/Loading';
import ProductFilter from '../components/ProductFilter';
import ProductFilterPopover from '../components/ProductFilterPopover';
import ProductSortBy from '../components/ProductSortBy';
import GridView from '../components/ProductsPage/GridView';
import ListView from '../components/ProductsPage/ListView';
import { useLocalStorage } from '../helpers';
import { setProducts } from '../state/productsSlice';
import { setPriceVisibility } from '../state/userSlice';
import { MainContainer } from '../styles';
import {
  CUSTOM_THEME_COLORS,
  MEDIUM_DEVICES_BREAKPOINT,
  NOT_FOUND_ROUTE,
} from '../variables';

const ProductsPage = () => {
  const SMALL_DEVICES = useMediaQuery(
    `(max-width:${MEDIUM_DEVICES_BREAKPOINT})`
  );
  const sortBy = useSelector((state) => state.products.sortBy);
  const customerId = useSelector((state) => state.user.selectedClient.id);
  const products = useSelector((state) => state.products.products);
  const priceIsVisible = useSelector((state) => state.user.priceIsVisible);
  const [cookies] = useCookies(['userToken']);
  const showPriceToUser = !!cookies.userToken && priceIsVisible;
  const [layout, setLayout] = useLocalStorage('layout', 'grid');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { categorySlug, subcategorySlug, productTypesSlugs } = useParams();
  let [searchParams] = useSearchParams();

  const [availableFilters, setAvailableFilters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const productCategoriesParams = {
    brands: categorySlug,
    subcategories: subcategorySlug,
    productTypes: productTypesSlugs,
  };

  //  update 'search params' values to array
  const searchParamsEntries = Object.values([...searchParams]).reduce(
    (acc, [key, value]) => ({ ...acc, [key]: value.split(',') }),
    {}
  );

  //  merge 'search params' & 'product categories slugs'
  //  remove entrie if it has no 'value'
  //  IF 'key' exists » concat new 'value'
  const productParamsMerged = Object.entries(productCategoriesParams).reduce(
    (acc, [key, value]) =>
      value
        ? {
            ...acc,
            [key]: acc?.[key] ? acc?.[key]?.concat(value) : value.split(','),
          }
        : { ...acc },
    { ...searchParamsEntries }
  );

  useEffect(() => {
    setIsLoading(true);
    getProducts({
      sortBy,
      productFilters: productParamsMerged,
    })
      .then(({ displayedProducts, displayedFilters }) => {
        dispatch(setProducts(displayedProducts));
        setAvailableFilters(displayedFilters);
        setIsLoading(false);
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
        if (status === 404) navigate(`/${NOT_FOUND_ROUTE}`);
        setIsLoading(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    categorySlug,
    subcategorySlug,
    productTypesSlugs,
    sortBy,
    searchParams,
    dispatch,
  ]);

  //	get category title
  // const categoryTitle = availableFilters.filter((filter) => filter.id === 'category')?.[0]?.options;
  const categoryTitle = productParamsMerged.brands?.[0];
  const categoryTitleCapitalized = capitalize(categoryTitle);

  //  IF we are in 'collection' view » show collections sidebar filters
  const isProductCollection = subcategorySlug === undefined;
  //	remove 'category/subcategories' filters & remove filters without options » sidebar filters
  const availableFiltersFiltered = availableFilters.filter((filter) =>
    isProductCollection
      ? filter.options.length !== 0 && filter.id !== 'category'
      : filter.options.length !== 0 &&
        filter.id !== 'category' &&
        filter.id !== 'subcategories'
  );

  return (
    <MainContainer>
      <Container>
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          {!SMALL_DEVICES && (
            <Grid md={3}>
              {/* show filters on the side in big screens */}
              {availableFiltersFiltered.map((filter) => (
                <ProductFilter
                  key={filter.id}
                  filter={filter}
                  activeFilters={productParamsMerged}
                />
              ))}
            </Grid>
          )}
          <Grid
            md={8.5}
            mdOffset={0.5}
          >
            <Box sx={{ flex: 1 }}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  marginBottom: { xs: '0', md: 4 },
                }}
              >
                {/* attention to subcategory title */}
                <Typography
                  variant='h3'
                  sx={{ flex: 1 }}
                >
                  {categoryTitleCapitalized && (
                    <>
                      {categoryTitleCapitalized}
                      <Typography
                        variant='overline'
                        marginLeft={1}
                        sx={{
                          textTransform: 'initial',
                        }}
                      >
                        ({products.length} resultados)
                      </Typography>
                    </>
                  )}
                </Typography>

                {!SMALL_DEVICES && (
                  <>
                    {!!cookies.userToken && (
                      <>
                        <FormGroup>
                          <FormControlLabel
                            control={<Switch checked={!priceIsVisible} />}
                            title='Ocultar preço'
                            label='Ocultar preço'
                            slotProps={{ typography: { variant: 'small' } }}
                            onChange={() =>
                              dispatch(setPriceVisibility(!priceIsVisible))
                            }
                            sx={{ marginRight: 0 }}
                          />
                        </FormGroup>
                        <Divider
                          orientation='vertical'
                          flexItem
                          sx={{
                            borderColor: CUSTOM_THEME_COLORS.gray,
                            marginX: 2,
                          }}
                        />
                      </>
                    )}

                    <ProductSortBy />

                    <Divider
                      orientation='vertical'
                      flexItem
                      sx={{
                        borderColor: CUSTOM_THEME_COLORS.gray,
                        marginLeft: 1,
                        marginRight: 2,
                      }}
                    />

                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <Icon
                        title='Ver em grelha'
                        sx={{
                          backgroundColor: layout === 'grid' && '#D9D9D9',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer',
                          transition: 'background-color 200ms ease',
                          '&:hover': {
                            backgroundColor: '#D9D9D9',
                          },
                        }}
                        onClick={() => setLayout('grid')}
                      >
                        <FiGrid size={16} />
                      </Icon>

                      <Icon
                        title='Ver em lista'
                        sx={{
                          backgroundColor: layout === 'list' && '#D9D9D9',
                          borderRadius: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          width: '32px',
                          height: '32px',
                          cursor: 'pointer',
                          transition: 'background-color 200ms ease',
                          '&:hover': {
                            backgroundColor: '#D9D9D9',
                          },
                        }}
                        onClick={() => setLayout('list')}
                      >
                        <FiList size={16} />
                      </Icon>
                    </Box>
                  </>
                )}
              </Box>

              {SMALL_DEVICES && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    marginTop: 2,
                    marginBottom: 1,
                  }}
                >
                  <ProductFilterPopover
                    availableFilters={availableFiltersFiltered}
                    activeFilters={productParamsMerged}
                  />

                  <ProductSortBy />
                </Box>
              )}

              {isLoading ? (
                <Loading />
              ) : (
                <>
                  {!products.length ? (
                    <Typography variant='h6'>{`Não foram encontrados produtos. Utilize uma pesquisa diferente.`}</Typography>
                  ) : (
                    <>
                      {layout === 'grid' ? (
                        <GridView
                          products={products}
                          showPriceToUser={showPriceToUser}
                        />
                      ) : (
                        <ListView
                          products={products}
                          showPriceToUser={showPriceToUser}
                          customerId={customerId}
                        />
                      )}
                    </>
                  )}
                </>
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </MainContainer>
  );
};

export default ProductsPage;
