import { LoadingButton } from '@mui/lab';
import { Box, Snackbar, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../../api/login';
import { StyledFormInputLabel } from '../../styles';
import { CUSTOM_THEME_COLORS, USER_REGISTER_TITLE } from '../../variables';
import RegisterSuccess from './RegisterSuccess';

const UserRegisterForm = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [userData, setUserData] = useState({
    company: '',
    taxNumber: '',
    phone: '',
    email: '',
    name: '',
    password: '',
    confirmPassword: '',
  });

  const [passwordConfirmationError, setPasswordConfirmationError] = useState({
    password: '',
    confirmPassword: '',
  });

  function validatePasswordConfirmation(e) {
    const { name, value } = e.target;

    setPasswordConfirmationError((prev) => {
      const passwordInputs = { ...prev, [name]: '' };

      switch (name) {
        case 'password':
          if (!value) {
            passwordInputs[name] = 'Por favor insira palavra-passe.';
          } else if (userData.confirmPassword && value !== userData.confirmPassword) {
            passwordInputs['confirmPassword'] = 'Confirmação da palavra-passe não corresponde.';
          } else {
            passwordInputs['confirmPassword'] = userData.confirmPassword ? '' : passwordConfirmationError.confirmPassword;
          }
          break;

        case 'confirmPassword':
          if (!value) {
            passwordInputs[name] = 'Por favor confirme palavra-passe.';
          } else if (userData.password && value !== userData.password) {
            passwordInputs[name] = 'Confirmação da palavra-passe não corresponde.';
          }
          break;

        default:
          break;
      }

      return passwordInputs;
    });
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    setUserData((values) => ({ ...values, [name]: value }));

    validatePasswordConfirmation(e);
  }

  function handleSubmit(e) {
    e.preventDefault();

    setLoading(true);

    if (
      !userData.company ||
      !userData.taxNumber ||
      !userData.phone ||
      !userData.email ||
      !userData.name ||
      !userData.password ||
      !userData.confirmPassword
    ) {
      setLoading(false);
      return;
    }

    registerUser(userData)
      .then(() => {
        setLoading(false);
        setShowConfirmation(true);
      })
      .catch(
        ({
          response: {
            data: { errors },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setLoading(false);

            const errorMessage = errors.reduce(
              (prevValue, { msg, value }) => `${prevValue} ${value}: ${msg}.
              `,
              ''
            );

            setError(true);
            setErrorMessage(errorMessage);
          }
        }
      );
  }

  if (showConfirmation) {
    return <RegisterSuccess />;
  }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        width: { xs: '80vw', md: '30vw' },
        marginTop: 4,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <StyledFormInputLabel sx={{ textAlign: 'center', marginBottom: 1 }}>{USER_REGISTER_TITLE}</StyledFormInputLabel>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label='Nome'
          type='text'
          name='name'
          required
          value={userData.name}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label='Contacto'
          type='text'
          name='phone'
          required
          value={userData.phone}
          onChange={handleInputChange}
        />
      </Box>

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
        }}
      >
        <TextField
          fullWidth
          label='Empresa'
          type='text'
          name='company'
          required
          value={userData.company}
          onChange={handleInputChange}
        />
        <TextField
          fullWidth
          label='NIF'
          type='text'
          name='taxNumber'
          required
          value={userData.taxNumber}
          onChange={handleInputChange}
        />
      </Box>

      <TextField
        fullWidth
        label='Email'
        type='email'
        name='email'
        required
        value={userData.email}
        onChange={handleInputChange}
      />

      <Box
        sx={{
          display: 'flex',
          gap: 2,
          width: '100%',
        }}
      >
        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            fullWidth
            label='Palavra-passe'
            type='password'
            name='password'
            required
            value={userData.password}
            onChange={handleInputChange}
            onBlur={validatePasswordConfirmation}
            error={passwordConfirmationError.password}
          />

          {passwordConfirmationError.password && (
            <Typography
              variant='caption'
              color={CUSTOM_THEME_COLORS.red}
            >
              {passwordConfirmationError.password}
            </Typography>
          )}
        </Box>

        <Box
          sx={{
            width: '100%',
            gap: 1,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <TextField
            fullWidth
            label='Confirmar Palavra-passe'
            type='password'
            name='confirmPassword'
            required
            value={userData.confirmPassword}
            onChange={handleInputChange}
            onBlur={validatePasswordConfirmation}
            error={passwordConfirmationError.confirmPassword}
          />

          {passwordConfirmationError.confirmPassword && (
            <Typography
              variant='caption'
              color={CUSTOM_THEME_COLORS.red}
            >
              {passwordConfirmationError.confirmPassword}
            </Typography>
          )}
        </Box>
      </Box>

      <LoadingButton
        variant='contained'
        type='submit'
        title='Submeter registo'
        sx={{
          marginTop: 3,
        }}
        loading={loading}
      >
        Submeter registo
      </LoadingButton>

      <Snackbar
        autoHideDuration={10000}
        message={errorMessage}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        open={error}
        onClose={(reason) => {
          if (reason === 'clickaway') return;

          setError(false);
        }}
        ContentProps={{
          sx: { backgroundColor: CUSTOM_THEME_COLORS.lightGray, whiteSpace: 'pre-wrap' },
        }}
      />
    </Box>
  );
};

export default UserRegisterForm;
