import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import React from 'react';
import { Link } from 'react-router-dom';
import defaultImage from '../assets/default_image.png';
import { formatCurrency } from '../constants/utils';
import { imageOnError } from '../helpers';
import { CUSTOM_THEME_COLORS } from '../variables';
import ProductTitleFormatter from './ProductTitleFormatter/ProductTitleFormatter';
import ProductBadge from './ProductBadge';
import ProductStock from './ProductStock';

const ProductCard = ({
  product,
  showStockAvailability = true,
  showPrice = true,
}) => {
  const theme = useTheme();
  const images = product.images;
  const { title, brand, reference, price, salePrice, onSale, stock, badge } =
    product;

  return (
    <Card
      className='product-card'
      square
      elevation={0}
      sx={{
        minWidth: '130px',
        maxWidth: '300px',
        textDecoration: 'none',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        paddingY: 2,
        '&:hover': {
          '.productCardTitle': {
            color: CUSTOM_THEME_COLORS.primary,
          },
        },
      }}
      component={Link}
      to={`/produtos/${product.id || product._id}`}
      title={title}
    >
      <Box
        className='productCardImage'
        sx={{
          position: 'relative',
          padding: { xs: 1, md: 2 },
          transition: 'transform 200ms ease',
        }}
      >
        {badge && <ProductBadge badge={badge} />}
        <CardMedia
          component='img'
          image={images.length !== 0 ? images?.[0] : defaultImage}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          padding: `${theme.spacing(1)} 0 0 0 !important`,
        }}
      >
        <Box>
          <Typography
            sx={{ fontSize: '.7rem', marginBottom: 1, fontWeight: 300 }}
          >
            {brand}
          </Typography>
          <Typography
            className='productCardTitle'
            sx={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: '2',
              WebkitBoxOrient: 'vertical',
              transition: 'color 200ms ease',
            }}
            fontWeight={700}
          >
            <ProductTitleFormatter
              title={title}
              reference={reference}
            />
          </Typography>
          <Typography
            sx={{ fontSize: '.85rem', marginTop: 0.5 }}
          >{`Ref: ${reference}`}</Typography>
        </Box>

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            marginTop: 2,
          }}
        >
          {showStockAvailability && <ProductStock stock={stock} />}

          {showPrice &&
            (onSale ? (
              <Box
                sx={{
                  display: 'flex',
                  gap: 1,
                }}
              >
                <Typography
                  sx={{
                    fontWeight: 500,
                    textDecoration: 'line-through',
                  }}
                >
                  {formatCurrency(price)}
                </Typography>
                <Typography sx={{ fontWeight: 600, color: CUSTOM_THEME_COLORS.primary }}>
                  {formatCurrency(salePrice)}
                </Typography>
              </Box>
            ) : (
              <Typography sx={{ fontWeight: 600 }}>
                {formatCurrency(price)}
              </Typography>
            ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
