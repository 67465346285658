const STORES = [
  {
    title: 'Geonatlife',
    address: {
      street:
        'Parque Empresarial Vialonga - Granja, 25 Casal do Bagulho - Granja de Alpriate',
      zipCode: '2625-607 Vialonga',
      city: '',
      country: 'Portugal',
    },
    phone: '+351 914 936 495',
    fax: '',
    email: 'info@geonatlife.com',
  },
];

export default STORES;
