import { Box, Grid, Typography } from '@mui/material';
import React from 'react';
import { COMPANY_INFO, CUSTOM_THEME_COLORS } from '../../variables';

const ContactUs = () => {
  const {
    address: { street, zipCode, city },
    phone,
    fax,
    email,
  } = COMPANY_INFO;

  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={1.5}
        sx={{
          textTransform: 'uppercase',
          fontSize: '1rem',
          color: CUSTOM_THEME_COLORS.primary,
        }}
      >
        Contacte-nos
      </Typography>

      <Typography>
        {street} <br /> {zipCode}
        <br />
        {city}
      </Typography>

      {phone && (
        <Box sx={{ marginTop: 1.5 }}>
          <Typography
            component={'a'}
            href={`tel:${phone}`}
            title={phone}
            sx={{ display: 'block', color: 'black', textDecoration: 'none' }}
          >
            <strong>T.</strong> {phone}
          </Typography>
          <Typography variant='small'>
            (Chamada para a rede fixa nacional)
          </Typography>
        </Box>
      )}

      {fax && (
        <Typography sx={{ marginTop: 1.5 }}>
          <strong>Fax.</strong> {fax}
        </Typography>
      )}

      {email && (
        <Typography
          component={'a'}
          href={`mailto:${email}`}
          title={email}
          sx={{
            display: 'block',
            color: 'black',
            textDecoration: 'none',
            marginTop: 1.5,
          }}
        >
          <strong>E.</strong> {email}
        </Typography>
      )}
    </Grid>
  );
};

export default ContactUs;
