import { formatTextToTitleCase } from '../constants/utils';
import defaultImage from '../assets/default_image.png';
import {
  generateRandomPrice,
  // generateRandomStringAndNumbers,
} from '../helpers';

const FILTERS_TITLES = {
  subcategories: 'Categoria',
  productTypes: 'Subcategoria',
  brands: 'Marca',
  sizes: 'Dimensões',
};

function mapProduct(product) {
  const randomPrice = generateRandomPrice();

  //  update interface of 'related'
  const hasRelated = Boolean(
    product?.related !== undefined && product?.related.length !== 0
  );
  const relatedUpdated = hasRelated
    ? product?.related.map((product) => mapProduct(product))
    : [];

  //  update interface of 'variants'
  const hasVariants = Boolean(
    product?.variants && product?.variants.length !== 0
  );

  const variantsUpdated = hasVariants
    ? product?.variants?.map((product) => {
        var discount = 10;
        return mapProduct({
          ...product,
          quantity: 0,
          discount: discount,
          finalPrice: randomPrice,
        });
      })
    : [];

  return {
    ...product,
    id: product?._id || product?.id,
    title: product?.title,
    brand: product?.brand,
    description: product?.description,
    reference: product?.reference,
    images: product?.images?.length ? product?.images : [defaultImage],
    price: product?.price ? product?.price : randomPrice,
    onSale: product?.onSale,
    salePrice: product?.onSale ? product?.salePrice : null,
    stock: product?.stock || 99,
    quantity: product?.quantity,
    video: product?.video,
    datasheet: product?.datasheet,
    params: product?.params,
    date: product?.erp_update_date || product?.date,
    badge: product?.badge,
    related: relatedUpdated,
    variants: variantsUpdated,
  };
}

function mapCategory(category) {
  return {
    ...category,
    id: category._id,
    text: formatTextToTitleCase(category.title),
    // date: category.erp_update_date,
    url: category.url,
    subcategories: category.subcategories.map((subcategory) => ({
      ...subcategory,
      id: subcategory._id,
      text: formatTextToTitleCase(subcategory.title.toString()),
      // date: subcategory.erp_update_date,
      url: `/${category.url}/${subcategory.url}`,
      productTypes: subcategory.productTypes.map((productType) => ({
        ...productType,
        id: productType._id,
        text: formatTextToTitleCase(productType.title.toString()),
        // date: productType.erp_update_date,
        url: `/${category.url}/${subcategory.url}/${productType.url}`,
      })),
    })),
  };
}

function mapFilters(filters) {
  return Object.entries(filters).map(([key, value]) => ({
    id: key,
    title: FILTERS_TITLES[key],
    options: value,
  }));
}

export { mapProduct, mapCategory, mapFilters };
