import { Box, CardMedia, Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import React from 'react';

const VideoCard = ({ video }) => {
  let videoUrl = null;

  if (video !== ' ') {
    videoUrl = new URL(video);
    const videoUrlParams = videoUrl.searchParams;
    const videoID = videoUrlParams.get('v');
    if (videoID) video = `https://www.youtube.com/embed/${videoID}`;
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableBody>
          <TableRow>
            <TableCell
              sx={{
                padding: { xs: '16px', md: '32px' },
              }}
            >
              {video !== ' ' ? (
                <Box
                  sx={{
                    maxWidth: { xs: '100%', md: '75%' },
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <Box
                    sx={{
                      paddingTop: '56.25%' /* 16:9 Aspect Ratio (divide 9 by 16 = 0.5625) */,
                      position: 'relative',
                    }}
                  >
                    <CardMedia
                      component='iframe'
                      src={video}
                      controls
                      sx={{
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                      }}
                    />
                  </Box>
                </Box>
              ) : (
                <>Não existe video disponível</>
              )}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default VideoCard;
