import {
  Box,
  Icon,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { FiCheck, FiEdit2, FiPercent } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';
import { formatCurrency } from '../../../../constants/utils';
import { StyledTextButton } from '../../../../styles';
import { updateCartProducts } from '../../../../api/checkout';
import DiscountSelectorModal from '../DiscountSelectorModal';
import SummaryProductInfo from './SummaryProductInfo';
import SummaryQuantity from './SummaryQuantity';
import { setCart, setCartTotal, setDiscount } from '../../../../state/cartSlice';

const SummaryTable = ({ customerId }) => {
  const dispatch = useDispatch();
  const [isApplyDiscountOpen, setIsApplyDiscountOpen] = useState(false);
  const cart = useSelector((state) => state.cart.cart);
  const user = useSelector((state) => state.user);
  const [iconButton, setIconButton] = useState(null);
  const showDiscountOptions = user.role === 'seller';
  const [editDiscountToProduct, setEditDiscountToProduct] = useState();

  const updateTotalWithDiscount = (product, discount, priceWithDiscount) => {
    const productWithDiscount = [
      {
        ...product,
        customer_price: priceWithDiscount,
        discount,
      },
    ];

    // POST REQUEST » it will trigger a cart object change
    updateCartProducts(productWithDiscount, customerId).then(({ cart_items, total, total_discount }) => {
      dispatch(setCart(cart_items));
      dispatch(setDiscount(total_discount));
      dispatch(setCartTotal(total));
    });
  };

  return (
    <>
      <TableContainer
        component={Paper}
        sx={{ marginY: 1 }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{ minWidth: { xs: '275px', md: 'initial' } }}>Detalhes</TableCell>
              <TableCell sx={{ minWidth: { xs: '140px', md: 'initial' } }}>Quantidade</TableCell>
              <TableCell sx={{ minWidth: { xs: '150px', md: 'initial' } }}>Preço</TableCell>
              {showDiscountOptions && (
                <TableCell sx={{ minWidth: { xs: '240px', md: 'initial' } }}>Desconto de Vendedor</TableCell>
              )}
              <TableCell sx={{ minWidth: { xs: '100px', md: 'initial' } }}>Subtotal</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {Array.isArray(cart) &&
              cart.map((product, index) => {
                const {
                  _id: id,
                  title,
                  brand,
                  reference,
                  price,
                  salePrice,
                  onSale,
                  quantity,
                  images,
                  discount = 0,
                } = product;
                const pricePerUnit = onSale ? salePrice : price;
                const hasDiscount = discount > 0;
                const subtotalWithDiscount = parseFloat(
                  Math.fround(pricePerUnit * quantity - (pricePerUnit * quantity * discount) / 100).toFixed(2)
                );

                return (
                  <TableRow
                    key={`${reference}-${id}`}
                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                  >
                    <TableCell>
                      {/* designation */}
                      <SummaryProductInfo
                        id={id}
                        images={images}
                        title={title}
                        reference={reference}
                        brand={brand}
                      />
                    </TableCell>

                    <TableCell>
                      {/* quantity */}
                      <SummaryQuantity
                        quantity={quantity}
                        id={id}
                        reference={reference}
                        cart={cart}
                        customerId={customerId}
                      />
                    </TableCell>

                    <TableCell>
                      {/* your price */}
                      {onSale ? (
                        <Box
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 1,
                          }}
                        >
                          <Typography sx={{ textDecoration: 'line-through' }}>{formatCurrency(price)}</Typography>
                          <Typography sx={{ fontWeight: 600, color: 'danger.main' }}>
                            {formatCurrency(pricePerUnit)}
                          </Typography>
                        </Box>
                      ) : (
                        <Typography>{formatCurrency(pricePerUnit)}</Typography>
                      )}
                    </TableCell>

                    {/* seller discount */}
                    {showDiscountOptions && (
                      <TableCell>
                        <StyledTextButton
                          title={hasDiscount ? 'Editar desconto' : 'Aplicar desconto'}
                          sx={{ gap: 1, color: hasDiscount ? 'danger.main' : 'inherit' }}
                          onMouseEnter={() => {
                            return hasDiscount && setIconButton(index);
                          }}
                          onMouseLeave={() => {
                            return hasDiscount && setIconButton(null);
                          }}
                          onClick={() => {
                            setEditDiscountToProduct(product);
                            setIsApplyDiscountOpen(true);
                          }}
                        >
                          <Icon
                            sx={{
                              backgroundColor: '#D9D9D9',
                              borderRadius: '100%',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            {hasDiscount ? (
                              iconButton === index ? (
                                <FiEdit2 size={12} />
                              ) : (
                                <FiCheck size={12} />
                              )
                            ) : (
                              <FiPercent size={12} />
                            )}
                          </Icon>
                          {hasDiscount ? `Desconto ${discount}%` : `Aplicar desconto`}
                        </StyledTextButton>
                      </TableCell>
                    )}

                    <TableCell>
                      {/* subtotal */}
                      <Box
                        sx={{
                          flex: 1,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          gap: 1,
                        }}
                      >
                        <Typography
                          sx={{
                            textDecoration: hasDiscount && 'line-through',
                            fontWeight: !hasDiscount && 600,
                          }}
                        >
                          {formatCurrency(pricePerUnit * quantity)}
                        </Typography>

                        {hasDiscount && (
                          <Typography
                            sx={{
                              color: hasDiscount && 'danger.main',
                              fontWeight: 600,
                            }}
                          >
                            {formatCurrency(subtotalWithDiscount)}
                          </Typography>
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {isApplyDiscountOpen && (
        <DiscountSelectorModal
          isApplyDiscountOpen={isApplyDiscountOpen}
          setIsApplyDiscountOpen={setIsApplyDiscountOpen}
          updateTotalWithDiscount={updateTotalWithDiscount}
          product={editDiscountToProduct}
        />
      )}
    </>
  );
};

export default SummaryTable;
