import axios from 'axios';
import { API_URL } from '../variables';
import { getHeaders } from './login';
import { mapFilters, mapProduct } from './mapApiInterface';

//  GET

function getProduct(id) {
  return axios
    .get(`${API_URL}/products/${id}`, getHeaders())
    .then(({ data: { data: product } }) => {
      const updatedProduct = mapProduct(product);
      return updatedProduct;
    });
}

function getProducts({ productFilters = {}, sortBy = 'date:desc' }) {
  const productParams = { ...productFilters, sortBy };
  const productParamsString = new URLSearchParams(productParams).toString();

  return axios
    .get(`${API_URL}/products?${productParamsString}`, getHeaders())
    .then(({ data: { products, filters } }) => {
      const displayedProducts = products.map((product) => mapProduct(product));
      const displayedFilters = mapFilters(filters);
      return { displayedFilters, displayedProducts };
    });
}

function getLatestProducts() {
  return axios
    .get(`${API_URL}/products?sortBy=date:desc&limit=20`, getHeaders())
    .then(({ data: { products } }) => {
      return products.map((product) => mapProduct(product));
    });
}

function getProductsOnCampaign() {
  return axios
    .get(`${API_URL}/products?sale=true`, getHeaders())
    .then(({ data: { products } }) => {
      return products.map((product) => mapProduct(product));
    });
}

function getSearchedProducts(input, sortBy = 'name:asc') {
  return axios
    .get(`${API_URL}/search?query=${input}&sortBy=${sortBy}`, getHeaders())
    .then(({ data: { products } }) =>
      products.map((product) => mapProduct(product))
    );
}

// POST

function addProductsToCart(newProducts, customerId) {
  return axios
    .post(
      `${API_URL}/cart/add`,
      { products: newProducts, customerId },
      getHeaders()
    )
    .then(({ data: { cart } }) => cart);
}

function addProductToShoppingList(listId, product_id) {
  return axios
    .post(
      `${API_URL}/shopping-lists/${listId}/add`,
      { product_id },
      getHeaders()
    )
    .then(({ data: { lists } }) => lists);
}

export {
  getProduct,
  getProducts,
  getLatestProducts,
  getProductsOnCampaign,
  getSearchedProducts,
  addProductsToCart,
  addProductToShoppingList,
};
