import { Grid, Link, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { CUSTOM_THEME_COLORS, PAGES_SLUGS } from '../../variables';

const CustomerSupport = () => {
  return (
    <Grid
      item
      xs={3}
    >
      <Typography
        variant='h4'
        marginBottom={1.5}
        sx={{ textTransform: 'uppercase', fontSize: '1rem', color: CUSTOM_THEME_COLORS.primary }}
      >
        Apoio ao Cliente
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={RouterLink}
        to={PAGES_SLUGS.institutional.termsAndConditions}
        title='Termos e Condições'
        sx={{
          display: 'block',
        }}
      >
        Termos e Condições
      </Typography>

      <Typography
        variant='link'
        marginBottom={0.5}
        component={RouterLink}
        to={PAGES_SLUGS.institutional.privacyPolicy}
        title='Política de Privacidade'
        sx={{
          display: 'block',
        }}
      >
        Política de Privacidade
      </Typography>

      <Link
        variant='link'
        marginBottom={0.5}
        href='https://www.livroreclamacoes.pt/Inicio/'
        target='_blank'
        rel='noreferrer'
        title='Livro de Reclamações'
        sx={{
          display: 'block',
          textDecoration: 'none',
        }}
      >
        Livro de Reclamações
      </Link>
    </Grid>
  );
};

export default CustomerSupport;
