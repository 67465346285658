import { Box, Container } from '@mui/material';
import Grid2 from '@mui/material/Unstable_Grid2';
import { Link } from 'react-router-dom';

const SecondaryBanner = ({ data }) => {
  return (
    <Container sx={{ paddingTop: { xs: 3, md: 6 } }}>
      <Grid2
        container
        spacing={{ xs: 1, md: 2 }}
      >
        {data.map(({ image, url }, index) => (
          <Grid2
            key={`homepage-campaign-${index}`}
            xs={12}
            sm={6}
          >
            <Link
              to={url}
              sx={{
                '&:hover': {
                  opacity: '.7',
                },
              }}
            >
              <Box
                component='img'
                src={image}
                width='100%'
                alt={`Campanha ${index}`}
                sx={{ aspectRatio: '16/9', objectFit: 'cover' }}
              />
            </Link>
          </Grid2>
        ))}
      </Grid2>
    </Container>
  );
};

export default SecondaryBanner;
