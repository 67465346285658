import { Box, Typography, useMediaQuery } from '@mui/material';
import { formatCurrency } from '../../../constants/utils';
import { StyledTextButton } from '../../../styles';
import { MEDIUM_DEVICES_BREAKPOINT } from '../../../variables';
import SummaryTable from './SummaryTable/SummaryTable';

const CartSummary = ({ setActiveStep, totalPrice, customerId }) => {
  const SMALL_DEVICES = useMediaQuery(
    `(max-width:${MEDIUM_DEVICES_BREAKPOINT})`
  );

  return (
    <>
      <Typography
        variant='h5'
        sx={{
          marginTop: { xs: 5, md: 6 },
          marginBottom: 4,
          textAlign: 'center',
        }}
      >
        Resumo do Carrinho
      </Typography>

      <SummaryTable customerId={customerId} />

      {totalPrice !== 0 && (
        <Box
          sx={{
            marginTop: 2,
            marginBottom: 4,
            display: 'flex',
            alignItems: 'center',
            flexDirection: SMALL_DEVICES && 'column',
          }}
        >
          <Typography
            variant='h6'
            sx={{
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              marginLeft: 'auto',
              fontSize: '1rem',
            }}
          >
            Total da encomenda:
            <Box sx={{ fontSize: '1.4em', fontWeight: 700 }}>
              {formatCurrency(totalPrice)}
            </Box>
          </Typography>
        </Box>
      )}

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondary'
          href='/'
          title='Voltar à Loja'
          sx={{ marginLeft: 'auto' }}
        >
          Voltar à Loja
        </StyledTextButton>

        <StyledTextButton
          variant='contained'
          onClick={() => setActiveStep(1)}
          title='Continuar'
        >
          Continuar
        </StyledTextButton>
      </Box>
    </>
  );
};

export default CartSummary;
