import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { formatCurrency } from '../../../constants/utils';
import { StyledOrderSummaryBox, StyledOrderSummaryText } from '../../../styles';
import { CUSTOM_THEME_COLORS } from '../../../variables';

const OrderSummaryCard = ({ cart: cartObject, total, cartTaxes, deliveryCost }) => {
  const theme = useTheme();
  const { cart, total: subTotal, discount } = cartObject;
  const totalUnits = cart.reduce((previousValue, item) => previousValue + item.quantity, 0);
  const totalReferences = cart.length;

  const orderSummary = [
    {
      text: 'Total de Unidades',
      value: totalUnits,
    },
    {
      text: 'Referências',
      value: totalReferences,
    },
    {
      text: 'Subtotal',
      value: formatCurrency(subTotal),
    },
    { text: 'IVA (23%)', value: formatCurrency(cartTaxes) },
    {
      text: 'Envio',
      value: formatCurrency(deliveryCost),
    },
    {
      text: 'Descontos',
      value: formatCurrency(discount),
    },
  ];
  const headerHeight = document.getElementsByTagName('header')[0]?.offsetHeight;

  return (
    <Box
      sx={{
        position: { md: 'sticky' },
        top: { md: `${headerHeight + 20}px` },
        width: { sm: '60%', md: '30%' },
        height: '100%',
        padding: 2,
        marginBottom: 3,
        border: `1px solid ${CUSTOM_THEME_COLORS.darkGray}`,
        borderRadius: theme.shape.borderRadius,
      }}
    >
      <Typography
        variant='h6'
        sx={{
          marginBottom: 3,
          fontWeight: 700,
          textAlign: 'center',
        }}
      >
        Resumo
      </Typography>

      {orderSummary.map(({ text, value }) => (
        <StyledOrderSummaryBox
          key={text}
          color={text === 'Descontos' && 'danger.main'}
        >
          <StyledOrderSummaryText>{text}</StyledOrderSummaryText>
          <StyledOrderSummaryText>{value}</StyledOrderSummaryText>
        </StyledOrderSummaryBox>
      ))}

      <StyledOrderSummaryBox sx={{ marginTop: 3, marginBottom: 0, alignItems: 'baseline' }}>
        <StyledOrderSummaryText sx={{ fontSize: '1.2rem', fontWeight: 700 }}>Total</StyledOrderSummaryText>
        <Typography sx={{ fontSize: '1.2rem', fontWeight: 700 }}>{formatCurrency(total)}</Typography>
      </StyledOrderSummaryBox>
    </Box>
  );
};

export default OrderSummaryCard;
