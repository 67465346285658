import { Box, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getProductUserHistory } from '../../api/user';
import ProductsCarousel from '../Carousel/ProductsCarousel';
import Loading from '../Loading';

const ProductsHistory = () => {
  const userRole = useSelector((state) => state.user.role);
  const selectedClient = useSelector((state) => state.user.selectedClient);
  const customerId = useSelector(
    (state) => state.user.selectedClient.id || state.user.id
  );
  const [isLoading, setIsLoading] = useState(false);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    getProductUserHistory(customerId).then(({ data }) => {
      setProducts(data.productHistory);
      setIsLoading(false);
    });
  }, [customerId]);

  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    400: {
      slidesPerView: 2,
    },
    600: {
      slidesPerView: 3,
    },
    800: {
      slidesPerView: 4,
    },
  };

  return (
    <Box
      sx={{
        flex: 3,
      }}
    >
      <Box marginBottom={2}>
        <Typography
          marginBottom={1}
          sx={{ fontWeight: 700, textTransform: 'uppercase' }}
        >
          Visualizações
        </Typography>
        <Typography variant='small'>
          Aqui poderá ver os últimos produtos visualizados por si.
        </Typography>
      </Box>

      {isLoading ? (
        <Loading />
      ) : (
        <>
          {userRole === 'seller' && (
            <Typography>
              {`Consulte os últimos produtos visualizados de `}{' '}
              <strong>{selectedClient.companyInfo.name}</strong>.
            </Typography>
          )}

          {!products.length ? (
            <Typography sx={{ marginY: 2 }}>
              Não existem produtos visualizados recentemente.
            </Typography>
          ) : (
            <ProductsCarousel
              products={products}
              breakpoints={breakpoints}
            />
          )}
        </>
      )}
    </Box>
  );
};

export default ProductsHistory;
