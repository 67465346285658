import { Box, Typography, useMediaQuery } from '@mui/material';
import { StyledFormTextfield, StyledTextButton } from '../styles';
import { MEDIUM_DEVICES_BREAKPOINT } from '../variables';

const BudgetRequestForm = () => {
  const SMALL_DEVICES = useMediaQuery(`(max-width:${MEDIUM_DEVICES_BREAKPOINT})`);

  const handleSubmit = (e) => {
    e.preventDefault();
  };

  return (
    <Box
      sx={{
        textAlign: 'center',
        marginY: 2,
      }}
    >
      <form onSubmit={handleSubmit}>
        <Typography
          marginBottom={2}
          variant='h4'
          fontWeight={700}
        >
          Pedido de Orçamento
        </Typography>

        <Typography marginBottom={2}>
          Mais informações? Preencha o seguinte formulário. <br /> Entraremos em contacto com a maior brevidade possível.
        </Typography>

        <Box
          sx={{
            width: SMALL_DEVICES ? '80%' : '700px',
            marginY: 4,
            marginX: 'auto',
          }}
        >
          <Box
            sx={{
              marginY: 1,
              display: 'flex',
              flexDirection: SMALL_DEVICES ? 'column' : 'row',
              gap: 1,
            }}
          >
            <StyledFormTextfield
              label='Nome'
              sx={{ flex: 1.6 }}
              required
            />
            <StyledFormTextfield
              label='Contacto'
              type='tel'
              sx={{ flex: 1 }}
              required
            />
            <StyledFormTextfield
              label='Email'
              type='email'
              sx={{ flex: 1.6 }}
              required
            />
          </Box>

          <StyledFormTextfield
            multiline
            fullWidth
            minRows={3}
            label='Mensagem'
            required
          />

          <StyledTextButton
            sx={{ marginY: 1 }}
            variant='contained'
            type='submit'
          >
            Submeter
          </StyledTextButton>
        </Box>
      </form>
    </Box>
  );
};

export default BudgetRequestForm;
