import { Typography } from '@mui/material';
import React from 'react';

const ProductStock = ({ stock }) => {
  const outOfStock = stock <= 0;

  return outOfStock ? (
    <Typography
      variant='caption'
      sx={{
        fontWeight: 600,
        color: 'danger.main',
      }}
    >
      Esgotado
    </Typography>
  ) : null;
};

export default ProductStock;
