import { LoadingButton } from '@mui/lab';
import { Box, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { checkDeliveryCost, confirmOrder } from '../../../api/checkout';
import { StyledTextButton } from '../../../styles';
import { DELIVERY_METHODS } from '../../../variables';
import DetailsForm from './DetailsForm/DetailsForm';
import OrderSummaryCard from './OrderSummaryCard';
import PopupNotification from '../../PopupNotification/PopupNotification';

const OrderDetails = ({ setActiveStep, cart: cartObject }) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const [notificationProps, setNotificationProps] = useState({
    isOpened: false,
    type: '',
    errorMessage: '',
  });

  const [shipping, setShipping] = useState('');
  const [deliveryCost, setDeliveryCost] = useState(0);
  const [address, setAddress] = useState('');
  const [observations, setObservations] = useState('');
  const [store, setStore] = useState('');
  const {
    id: cartId,
    cart: cartItems,
    total: totalCart,
    discount: totalDiscount,
  } = cartObject;
  const cartTaxes = totalCart * 0.23;
  const { user } = useSelector((state) => state);
  const { selectedClient, sellerInfo, role } = user;
  const userId = selectedClient.id;

  //	get user addresses
  const clientAddresses =
    role === 'customer' ? user.addresses : selectedClient.addresses;

  //	get user payment time
  const clientPaymentTime =
    role === 'customer'
      ? sellerInfo.paymentTime
      : selectedClient.payment_deadlines;

  const [orderDetails, setOrderDetails] = useState({
    paymentType: 'Transferência Bancária',
    paymentTime: clientPaymentTime,
    shipping,
    deliveryAtAddress: address,
    deliveryAtStore: store,
    notes: observations,
    total: 0,
    total_discount: totalDiscount,
    shipping_price: deliveryCost,
    cart: cartItems,
    cart_id: cartId,
  });

  useEffect(() => {
    const cartTotal = totalCart + cartTaxes + deliveryCost;
    setOrderDetails((values) => ({
      ...values,
      total: cartTotal,
    }));
  }, [totalCart, cartTaxes, deliveryCost]);

  useEffect(() => {
    //  IF shipping method is 'STORE'
    if (shipping === DELIVERY_METHODS.STORE) {
      setDeliveryCost(0);
      setOrderDetails((values) => ({
        ...values,
        shipping_price: 0,
      }));
      return;
    }

    //  IF shipping method is 'ADDRESS'
    const selectedAddress = clientAddresses.find(
      (addr) => addr.street === address
    );

    if (!selectedAddress) {
      return;
    }

    checkDeliveryCost(selectedAddress.zipCode, userId)
      .then(({ deliveryCost }) => {
        setDeliveryCost(deliveryCost);
        setOrderDetails((values) => ({
          ...values,
          shipping_price: deliveryCost,
        }));
      })
      .catch(({ response: { status } }) => {
        if (status === 401) navigate('/login');
      });
  }, [address, clientAddresses, shipping, navigate, userId]);

  function onShippingChange(e) {
    setShipping(e.target.value);
    setOrderDetails((values) => ({ ...values, shipping: e.target.value }));
  }

  function onAddressChange(e) {
    setAddress(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      deliveryAtAddress: e.target.value,
    }));
  }

  function onStoreChange(e) {
    setStore(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      deliveryAtStore: e.target.value,
    }));
  }

  function onObservationsChange(e) {
    setObservations(e.target.value);
    setOrderDetails((values) => ({
      ...values,
      notes: e.target.value,
    }));
  }

  function handleConfirmOrder(e) {
    e.preventDefault();

    setIsLoading(true);
    confirmOrder(orderDetails)
      .then(() => {
        setIsLoading(false);
        setActiveStep(2);
      })
      .catch(
        ({
          response: {
            data: { msg },
            status,
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setIsLoading(false);
            setNotificationProps((prevState) => ({
              ...prevState,
              isOpened: true,
              type: 'error',
              errorMessage: msg,
            }));
          }
        }
      );
  }

  return (
    <Box>
      <Typography
        variant='h5'
        sx={{
          marginTop: { xs: 5, md: 6 },
          marginBottom: 4,
          textAlign: 'center',
        }}
      >
        Informações da encomenda
      </Typography>

      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'initial' },
          alignItems: { sm: 'center', md: 'initial' },
          gap: { xs: 5, md: 6 },
        }}
      >
        <DetailsForm
          onObservationsChange={onObservationsChange}
          shipping={shipping}
          onShippingChange={onShippingChange}
          onAddressChange={onAddressChange}
          address={address}
          onStoreChange={onStoreChange}
          store={store}
          paymentTime={orderDetails.paymentTime}
          role={role}
          clientAddresses={clientAddresses}
        />

        <OrderSummaryCard
          cart={cartObject}
          total={orderDetails.total}
          cartTaxes={cartTaxes}
          deliveryCost={deliveryCost}
        />
      </Box>

      <Box
        sx={{
          marginY: 1,
          display: 'flex',
          alignItems: 'center',
          gap: 2,
        }}
      >
        <StyledTextButton
          variant='contained'
          color='secondary'
          onClick={() => setActiveStep(0)}
          title='Voltar atrás'
          sx={{ marginLeft: 'auto' }}
        >
          Voltar atrás
        </StyledTextButton>

        <LoadingButton
          variant='contained'
          type='submit'
          title='Finalizar encomenda'
          disabled={
            (shipping === DELIVERY_METHODS.STORE && store) ||
            (shipping === DELIVERY_METHODS.ADDRESS && address)
              ? false
              : true
          }
          loading={isLoading}
          onClick={handleConfirmOrder}
        >
          Finalizar encomenda
        </LoadingButton>

        <PopupNotification
          notificationProps={notificationProps}
          setNotificationProps={setNotificationProps}
        />
      </Box>
    </Box>
  );
};

export default OrderDetails;
