import { Box, Button } from '@mui/material';
import { memo } from 'react';
import { useState } from 'react';

const QuantitySelector = ({ product, setUpdatedProductQuantity, variant }) => {
  const { quantity, stock } = product;
  const [updatedQuantity, setUpdatedQuantity] = useState(quantity);

  const isOutOfStock = stock <= 0;
  const minQuantity = 1;

  const decrement = () => {
    if (updatedQuantity < minQuantity) return;

    const newQuantity = updatedQuantity - minQuantity;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity({ ...product, quantity: newQuantity });
  };

  const increment = () => {
    const newQuantity = updatedQuantity + minQuantity;
    setUpdatedQuantity(newQuantity);
    setUpdatedProductQuantity({ ...product, quantity: newQuantity });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 1,
      }}
    >
      <Button
        disabled={
          (variant && updatedQuantity === 0) ||
          (!variant && updatedQuantity === 1) ||
          isOutOfStock
        }
        sx={{
          minWidth: '28px',
          minHeight: '28px',
          lineHeight: 1,
          padding: 1,
        }}
        onClick={() => decrement()}
      >
        -
      </Button>

      <Box
        component='span'
        sx={{ minWidth: 2, textAlign: 'center' }}
      >
        {updatedQuantity}
      </Box>

      <Button
        disabled={isOutOfStock}
        sx={{
          minWidth: '28px',
          minHeight: '28px',
          lineHeight: 1,
          padding: 1,
        }}
        onClick={() => increment()}
      >
        +
      </Button>
    </Box>
  );
};

export default memo(QuantitySelector);
