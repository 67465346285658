import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Divider,
  Snackbar,
  Typography,
} from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { addProductsToCart } from '../api/products';
import { formatCurrency } from '../constants/utils';
import { imageOnError } from '../helpers';
import { updateCart } from '../state/cartSlice.js';
import { StyledTextButton } from '../styles';
import { CUSTOM_THEME_COLORS } from '../variables';
import ProductBadge from './ProductBadge';
import QuantitySelector from './ProductPage/ProductDetails/QuantitySelector';
import ProductStock from './ProductStock';
import ProductTitleFormatter from './ProductTitleFormatter/ProductTitleFormatter';

const ProductListCard = ({
  product,
  showPriceToUser,
  customerId,
  showStockAvailability,
}) => {
  const [quantity, setQuantity] = useState(1);
  const [productsForCart, setProductsForCart] = useState(new Map());
  const [addToCartConfirmation, setAddToCartConfirmation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const images = product.images;
  const brand = product.brand;
  const {
    id,
    title,
    description,
    reference,
    price,
    stock,
    salePrice,
    onSale,
    badge,
  } = product;

  const handleProductSelect = useCallback(
    (product) => {
      const newProductsForCart = new Map(productsForCart);

      newProductsForCart.set(product.reference, product);
      setProductsForCart(newProductsForCart);
    },
    [setProductsForCart, productsForCart]
  );

  function addToCart() {
    const products = Array.from(productsForCart.values());
    const newProducts = products.filter(({ quantity }) => quantity > 0);

    addProductsToCart(newProducts, customerId)
      .then((cart) => {
        dispatch(updateCart(cart));

        setAddToCartConfirmation(true);
      })
      .catch(
        ({
          response: {
            status,
            data: { msg },
          },
        }) => {
          if (status === 401) {
            navigate('/login');
          } else if (status === 400 || status > 401) {
            setErrorMessage(msg);
            console.error(
              `Error while adding product to cart at ProductListCard: ${msg}`
            );
          }
        }
      );

    setAddToCartConfirmation(false);
  }

  useEffect(() => {
    product.quantity = quantity;
    handleProductSelect(product);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card
      raised
      sx={{
        marginY: 3,
        display: 'flex',
        alignItems: 'flex-start',
        boxShadow: '0px 2px 8px 0px rgba(0,0,0,0.2)',
      }}
    >
      <Box
        title={title}
        component={Link}
        to={`/produtos/${id}`}
        sx={{
          position: 'relative',
          maxWidth: '200px',
          padding: { xs: 1, sm: 2, lg: 3 },
          flex: 1,
        }}
      >
        {badge && <ProductBadge badge={badge} />}

        <CardMedia
          component='img'
          image={images[0]}
          onError={imageOnError}
          alt={title}
          sx={{ maxWidth: '100%', objectFit: 'contain', aspectRatio: '1' }}
        />
      </Box>

      <CardContent
        sx={{
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          flex: 2,
          padding: '20px 20px 20px 10px !important',
        }}
      >
        <Box sx={{ width: '100%' }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              marginBottom: 1,
            }}
          >
            <Typography sx={{ fontSize: '.7rem' }}>{brand}</Typography>

            <Typography
              sx={{ fontSize: '.7rem' }}
            >{`Ref: ${reference}`}</Typography>
          </Box>

          <Typography
            sx={{
              display: 'flex',
              color: 'black',
              fontWeight: 700,
              textDecoration: 'none',
              transition: 'color 200ms ease',
              '&:hover': {
                color: CUSTOM_THEME_COLORS.primary,
              },
            }}
            marginBottom={1}
            title={title}
            component={Link}
            to={`/produtos/${id}`}
          >
            <ProductTitleFormatter
              title={title}
              reference={reference}
            />
          </Typography>

          <Typography
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              display: '-webkit-box',
              WebkitLineClamp: 3,
              lineClamp: 3,
              WebkitBoxOrient: 'vertical',
            }}
          >
            {description}
          </Typography>

          {showStockAvailability && <ProductStock stock={stock} />}
        </Box>

        {showPriceToUser && (
          <>
            <Divider
              orientation='horizontal'
              flexItem
              sx={{ marginY: 2 }}
            />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  gap: 4,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <Typography
                    variant='caption'
                    sx={{
                      marginBottom: 0.5,
                    }}
                  >
                    Preço
                  </Typography>
                  {onSale ? (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: 1,
                      }}
                    >
                      <Typography
                        sx={{ fontWeight: 600, textDecoration: 'line-through' }}
                      >
                        {formatCurrency(price)}
                      </Typography>
                      <Typography
                        sx={{ fontWeight: 600, color: 'danger.main' }}
                      >
                        {formatCurrency(salePrice)}
                      </Typography>
                    </Box>
                  ) : (
                    <Typography sx={{ fontWeight: 600 }}>
                      {formatCurrency(price)}
                    </Typography>
                  )}
                </Box>

                {product.stock > 0 && (
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <Typography variant='caption'>Quantidade</Typography>
                    <Box sx={{ marginLeft: '-8px' }}>
                      <QuantitySelector
                        product={product}
                        quantity={{ value: quantity, setQuantity }}
                        selectForCart={handleProductSelect}
                      />
                    </Box>
                  </Box>
                )}
              </Box>

              <Box>
                <StyledTextButton
                  variant='contained'
                  fullWidth
                  sx={{}}
                  onClick={addToCart}
                  disabled={product.stock <= 0}
                  title='Adicionar ao Carrinho'
                >
                  Adicionar ao Carrinho
                </StyledTextButton>
              </Box>
            </Box>
          </>
        )}
      </CardContent>
      {addToCartConfirmation && (
        <Snackbar
          autoHideDuration={4000}
          message={
            addToCartConfirmation ? 'Adicionado ao carrinho' : errorMessage
          }
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          open={addToCartConfirmation}
          ContentProps={{
            sx: {
              backgroundColor: addToCartConfirmation
                ? '#4FD290'
                : CUSTOM_THEME_COLORS.lightGray,
            },
          }}
          onClose={(reason) => {
            if (reason === 'clickaway') return;

            setAddToCartConfirmation(false);
          }}
        />
      )}
    </Card>
  );
};

export default ProductListCard;
