import {
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from '@mui/material';
import React from 'react';
import { FiChevronDown } from 'react-icons/fi';
import { useParams, useSearchParams } from 'react-router-dom';
import { slugify } from '../helpers';
import { ProductsFilters } from '../styles';

const ProductFilter = ({ filter, activeFilters }) => {
  const { id, title, options } = filter;
  const { productTypesSlugs } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const activeOptions = activeFilters[id] || [];

  function handleFilterChange(checked, optionSelected) {
    if (checked) {
      const paramAlreadyExists = searchParams.has(id);
      if (paramAlreadyExists) {
        const oldValue = searchParams.get(id);
        searchParams.set(id, [oldValue].concat(optionSelected));
      } else {
        searchParams.set(id, optionSelected);
      }
    } else {
      const oldValue = searchParams.get(id);
      const oldValueArray = oldValue.split(',');
      const oldValueFiltered = oldValueArray.filter((e) => e !== optionSelected);
      if (oldValueFiltered.length > 0) {
        searchParams.set(id, oldValueFiltered);
      } else {
        searchParams.delete(id);
      }
    }
    setSearchParams(searchParams);
  }

  return (
    <ProductsFilters
      defaultExpanded
      sx={{
        boxShadow: 'none',
      }}
    >
      <AccordionSummary
        expandIcon={
          <FiChevronDown
            size={24}
            color='#000'
          />
        }
      >
        <Typography
          sx={{
            fontWeight: '700',
          }}
        >
          {title}
        </Typography>
      </AccordionSummary>

      <Divider />

      <AccordionDetails>
        <FormGroup>
          {options.map((option, index) => {
            const optionName = slugify(option);
            return (
              <FormControlLabel
                key={`filter-${optionName}-${index}`}
                label={option}
                title={option}
                control={
                  <Checkbox
                    disabled={productTypesSlugs && productTypesSlugs === optionName}
                    checked={activeOptions.includes(optionName)}
                    onChange={(e) => handleFilterChange(e.target.checked, optionName)}
                  />
                }
                sx={{
                  marginRight: 0,
                }}
              />
            );
          })}
        </FormGroup>
      </AccordionDetails>
    </ProductsFilters>
  );
};

export default ProductFilter;
