import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import BrandsCard from '../BrandsCard';
import Grid from '@mui/material/Unstable_Grid2';

const Brands = ({ brands }) => {
  return (
    <Container sx={{ paddingY: { xs: 3, md: 6 } }}>
      <Typography
        variant='h2'
        marginBottom={1}
      >
        As nossas marcas
      </Typography>
      <Typography marginBottom={2}>
        Aqui poderá encontrar um conjunto de produtos associados ao seu produto.
      </Typography>

      <Box
        sx={{
          marginY: { xs: 2, md: 4 },
        }}
      >
        <Grid
          container
          spacing={{ xs: 1, md: 2 }}
        >
          {brands.map((brand) => (
            <Grid
              key={`brand-${brand._id}`}
              xs={6}
              sm={3}
              md={2}
            >
              <BrandsCard brand={brand} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Container>
  );
};

export default Brands;
