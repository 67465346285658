import { Box, Link, Typography } from '@mui/material';
import React from 'react';
import { IconContext } from 'react-icons';
import { FiChevronRight } from 'react-icons/fi';
import store1_location from '../assets/store1_location.png';

const StoreLocations = ({ stores }) => {
  return (
    <Box
      sx={{
        marginY: '100px',
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
      }}
    >
      {stores.map(({ title, address, phone, fax, email }) => {
        const addressAsString = Object.values(address).join(' ');

        const { street, zipCode, city, country } = address;

        return (
          <Box
            key={`store-location-${title}`}
            sx={{ display: 'flex' }}
          >
            <Box sx={{ flex: 1, textAlign: 'start' }}>
              <Typography
                fontSize='24px'
                fontWeight={700}
              >
                {title}
              </Typography>

              <Typography sx={{ maxWidth: '50%', marginTop: 2 }}>
                {street} <br /> {zipCode} {city} <br />
                {country}
              </Typography>

              {phone && (
                <>
                  <Typography
                    component={'a'}
                    href={`tel:${phone}`}
                    title={phone}
                    sx={{
                      display: 'block',
                      color: 'black',
                      textDecoration: 'none',
                      marginTop: 1.5,
                    }}
                  >
                    <strong>T.</strong> {phone}
                  </Typography>
                  <Typography variant='small'>
                    (Chamada para a rede fixa nacional)
                  </Typography>
                </>
              )}

              {fax && (
                <Typography
                  sx={{
                    marginTop: 1.5,
                  }}
                >{`F: ${fax}`}</Typography>
              )}

              {email && (
                <Typography
                  component={'a'}
                  href={`mailto:${email}`}
                  title={email}
                  sx={{
                    display: 'block',
                    color: 'black',
                    textDecoration: 'none',
                    marginTop: 1.5,
                  }}
                >
                  <strong>E.</strong> {email}
                </Typography>
              )}

              <Link
                href={`https://maps.google.com/?q=${addressAsString}`}
                target='_blank'
                rel='noreferrer'
                underline='none'
                title='Obter direções'
                sx={{
                  display: 'block',
                  fontWeight: 700,
                  marginTop: 3,
                }}
              >
                Obter direções
                <IconContext.Provider
                  value={{ style: { verticalAlign: 'middle' } }}
                >
                  <FiChevronRight />
                </IconContext.Provider>
              </Link>
            </Box>

            <Link
              href={`https://maps.google.com/?q=${addressAsString}`}
              target='_blank'
              rel='noreferrer'
              sx={{ flex: 1 }}
            >
              <img
                src={store1_location}
                width='100%'
                alt=''
              />
            </Link>
          </Box>
        );
      })}
    </Box>
  );
};

export default StoreLocations;
