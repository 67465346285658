import { Box } from '@mui/material';
import React, { useState } from 'react';
import { imageOnError } from '../../helpers';
import ThumbnailsCarousel from '../Carousel/ThumbnailsCarousel';

const ProductImages = ({ images }) => {
  const [shownImage, setShownImage] = useState(0);
  const [firstImageExists, setFirstImageExists] = useState(true);

  return (
    <Box
      sx={{
        maxWidth: '300px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}
      gap={firstImageExists && images.length > 1 && 3}
    >
      <Box
        component='img'
        src={images[shownImage]}
        alt='Imagem destacada'
        onError={(e) => {
          imageOnError(e);
          setFirstImageExists(false);
        }}
        sx={{
          aspectRatio: '1',
          objectFit: 'contain',
          maxWidth: '100%',
        }}
      />

      {firstImageExists && images.length > 1 && (
        <ThumbnailsCarousel
          images={images}
          shownImage={shownImage}
          setShownImage={setShownImage}
        />
      )}
    </Box>
  );
};

export default ProductImages;
